/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {WorkflowScript, WorkflowScriptFromJSON, WorkflowScriptToJSON,} from './WorkflowScript';

/**
 *
 * @export
 * @interface WorkflowScriptIReadOnlyCollectionApiResult
 */
export interface WorkflowScriptIReadOnlyCollectionApiResult {
    /**
     *
     * @type {boolean}
     * @memberof WorkflowScriptIReadOnlyCollectionApiResult
     */
    success?: boolean;
    /**
     *
     * @type {Array<WorkflowScript>}
     * @memberof WorkflowScriptIReadOnlyCollectionApiResult
     */
    content?: Array<WorkflowScript> | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowScriptIReadOnlyCollectionApiResult
     */
    message?: string | null;
}

export function WorkflowScriptIReadOnlyCollectionApiResultFromJSON(json: any): WorkflowScriptIReadOnlyCollectionApiResult {
    return WorkflowScriptIReadOnlyCollectionApiResultFromJSONTyped(json, false);
}

export function WorkflowScriptIReadOnlyCollectionApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowScriptIReadOnlyCollectionApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : (json['content'] === null ? null : (json['content'] as Array<any>).map(WorkflowScriptFromJSON)),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function WorkflowScriptIReadOnlyCollectionApiResultToJSON(value?: WorkflowScriptIReadOnlyCollectionApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'success': value.success,
        'content': value.content === undefined ? undefined : (value.content === null ? null : (value.content as Array<any>).map(WorkflowScriptToJSON)),
        'message': value.message,
    };
}

