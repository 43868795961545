/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanApiResult,
    BooleanApiResultFromJSON,
    WorkflowHistoryApiResult,
    WorkflowHistoryApiResultFromJSON,
    WorkflowHistoryInsertDto,
    WorkflowHistoryInsertDtoToJSON,
    WorkflowHistoryIReadOnlyCollectionApiResult,
    WorkflowHistoryIReadOnlyCollectionApiResultFromJSON,
    WorkflowHistoryUpdateDto,
    WorkflowHistoryUpdateDtoToJSON,
} from '../models';

export interface WorkflowHistoryDeleteByIdRequest {
    id: string;
}

export interface WorkflowHistoryGetByIdRequest {
    id: string;
}

export interface WorkflowHistoryGetPageRequest {
    page?: number;
    perPage?: number;
}

export interface WorkflowHistoryInsertRequest {
    workflowHistoryInsertDto?: WorkflowHistoryInsertDto;
}

export interface WorkflowHistorySearchRequest {
    criteria?: string;
    page?: number;
    perPage?: number;
}

export interface WorkflowHistoryUpdateRequest {
    workflowHistoryUpdateDto?: WorkflowHistoryUpdateDto;
}

/**
 * WorkflowHistoryApi - interface
 *
 * @export
 * @interface WorkflowHistoryApiInterface
 */
export interface WorkflowHistoryApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowHistoryApiInterface
     */
    workflowHistoryDeleteByIdRaw(requestParameters: WorkflowHistoryDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    workflowHistoryDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowHistoryApiInterface
     */
    workflowHistoryGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowHistoryGet(initOverrides?: RequestInit): Promise<WorkflowHistoryIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowHistoryApiInterface
     */
    workflowHistoryGetByIdRaw(requestParameters: WorkflowHistoryGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryApiResult>>;

    /**
     */
    workflowHistoryGetById(id: string, initOverrides?: RequestInit): Promise<WorkflowHistoryApiResult>;

    /**
     *
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowHistoryApiInterface
     */
    workflowHistoryGetPageRaw(requestParameters: WorkflowHistoryGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowHistoryGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowHistoryIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {WorkflowHistoryInsertDto} [workflowHistoryInsertDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowHistoryApiInterface
     */
    workflowHistoryInsertRaw(requestParameters: WorkflowHistoryInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryApiResult>>;

    /**
     */
    workflowHistoryInsert(workflowHistoryInsertDto?: WorkflowHistoryInsertDto, initOverrides?: RequestInit): Promise<WorkflowHistoryApiResult>;

    /**
     *
     * @param {string} [criteria]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowHistoryApiInterface
     */
    workflowHistorySearchRaw(requestParameters: WorkflowHistorySearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowHistorySearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowHistoryIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {WorkflowHistoryUpdateDto} [workflowHistoryUpdateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowHistoryApiInterface
     */
    workflowHistoryUpdateRaw(requestParameters: WorkflowHistoryUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryApiResult>>;

    /**
     */
    workflowHistoryUpdate(workflowHistoryUpdateDto?: WorkflowHistoryUpdateDto, initOverrides?: RequestInit): Promise<WorkflowHistoryApiResult>;

}

/**
 *
 */
export class WorkflowHistoryApi extends runtime.BaseAPI implements WorkflowHistoryApiInterface {

    /**
     */
    async workflowHistoryDeleteByIdRaw(requestParameters: WorkflowHistoryDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowHistoryDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowHistory/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowHistoryDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.workflowHistoryDeleteByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowHistoryGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowHistory/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowHistoryIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowHistoryGet(initOverrides?: RequestInit): Promise<WorkflowHistoryIReadOnlyCollectionApiResult> {
        const response = await this.workflowHistoryGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowHistoryGetByIdRaw(requestParameters: WorkflowHistoryGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowHistoryGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowHistory/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowHistoryApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowHistoryGetById(id: string, initOverrides?: RequestInit): Promise<WorkflowHistoryApiResult> {
        const response = await this.workflowHistoryGetByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowHistoryGetPageRaw(requestParameters: WorkflowHistoryGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowHistory/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowHistoryIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowHistoryGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowHistoryIReadOnlyCollectionApiResult> {
        const response = await this.workflowHistoryGetPageRaw({page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowHistoryInsertRaw(requestParameters: WorkflowHistoryInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowHistory/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowHistoryInsertDtoToJSON(requestParameters.workflowHistoryInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowHistoryApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowHistoryInsert(workflowHistoryInsertDto?: WorkflowHistoryInsertDto, initOverrides?: RequestInit): Promise<WorkflowHistoryApiResult> {
        const response = await this.workflowHistoryInsertRaw({workflowHistoryInsertDto: workflowHistoryInsertDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowHistorySearchRaw(requestParameters: WorkflowHistorySearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowHistory/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowHistoryIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowHistorySearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowHistoryIReadOnlyCollectionApiResult> {
        const response = await this.workflowHistorySearchRaw({
            criteria: criteria,
            page: page,
            perPage: perPage
        }, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowHistoryUpdateRaw(requestParameters: WorkflowHistoryUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowHistoryApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowHistory/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowHistoryUpdateDtoToJSON(requestParameters.workflowHistoryUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowHistoryApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowHistoryUpdate(workflowHistoryUpdateDto?: WorkflowHistoryUpdateDto, initOverrides?: RequestInit): Promise<WorkflowHistoryApiResult> {
        const response = await this.workflowHistoryUpdateRaw({workflowHistoryUpdateDto: workflowHistoryUpdateDto}, initOverrides);
        return await response.value();
    }

}
