import {BASE_PATH} from "@/assets/consts";
import {ref} from "vue";
import {defineStore} from "pinia";
import {useAuthStore} from "./authStore";
import {Project, ProjectApi} from "@/models";
import {Configuration} from "@/models/runtime";
import {useToast} from 'primevue/usetoast';

export const useProjectStore = defineStore({
    id: "projects",
    state: () => {
        const authStore = useAuthStore();
        const api = new ProjectApi(new Configuration({
            basePath: BASE_PATH,
            headers: {"Authorization": "Bearer " + authStore.user.token}
        }));
        const loading = ref(false);
        const projects = ref<Project[]>([]);
        const project = ref<Project>();
        const page = ref(1);
        const pageSize = ref(20);
        const totalRecords = ref(0);
        return {authStore, api, projects, project, loading, page, pageSize, totalRecords, toast: useToast()};
    },
    actions: {
        async getProjects() {
            this.loading = true;
            return await this.api.projectGetPage(this.page, this.pageSize).then(response => {
                this.loading = false;
                if (response.success) {
                    if (response.content != null && response.content != undefined) {
                        this.projects = response.content.data!;
                        if (response.content.totalRecords != null) this.totalRecords = response.content?.totalRecords;
                    }
                    return true;
                }
                return false;
            }).catch((error) => {
                if (error.status == 401) {
                    this.authStore.logout()
                } else {
                    this.toast.add({severity: "error", detail: error.message, summary: "API error occured", life: 3000})
                }
                return false;
            });
        },
        async save(project: Project) {
            this.loading = true;
            if (project.projectId == null) {
                return await this.api.projectInsert(project).then(response => {
                    this.loading = false;
                    if (response.success) {
                        if (response.content != null && response.content != undefined)
                            this.project = response.content!;
                        return true;
                    }
                    return false;
                }).catch((error) => {
                    if (error.status == 401) {
                        this.authStore.logout()
                    } else {
                        this.toast.add({
                            severity: "error",
                            detail: error.message,
                            summary: "API error occured",
                            life: 3000
                        })
                    }
                    return false;
                });
            } else {
                return await this.api.projectUpdate(project).then(response => {
                    this.loading = false;
                    if (response.success) {
                        if (response.content != null && response.content != undefined)
                            this.project = response.content!;
                        return true;
                    }
                    return false;
                }).catch((error) => {
                    if (error.status == 401) {
                        this.authStore.logout()
                    } else {
                        this.toast.add({
                            severity: "error",
                            detail: error.message,
                            summary: "API error occured",
                            life: 3000
                        })
                    }
                    return false;
                });
            }

        }
    }
});