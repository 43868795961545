/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanApiResult,
    BooleanApiResultFromJSON,
    ProjectApiResult,
    ProjectApiResultFromJSON,
    ProjectInsertDto,
    ProjectInsertDtoToJSON,
    ProjectIReadOnlyCollectionApiResult,
    ProjectIReadOnlyCollectionApiResultFromJSON,
    ProjectPageOfApiResult,
    ProjectPageOfApiResultFromJSON,
    ProjectUpdateDto,
    ProjectUpdateDtoToJSON,
} from '../models';

export interface ProjectDeleteByIdRequest {
    id: string;
}

export interface ProjectGetByIdRequest {
    id: string;
}

export interface ProjectGetForWorkflowByIdRequest {
    id: string;
    page?: number;
    perpage?: number;
}

export interface ProjectGetPageRequest {
    page?: number;
    perPage?: number;
}

export interface ProjectInsertRequest {
    projectInsertDto?: ProjectInsertDto;
}

export interface ProjectSearchRequest {
    criteria?: string;
    page?: number;
    perPage?: number;
}

export interface ProjectUpdateRequest {
    projectUpdateDto?: ProjectUpdateDto;
}

/**
 * ProjectApi - interface
 *
 * @export
 * @interface ProjectApiInterface
 */
export interface ProjectApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectDeleteByIdRaw(requestParameters: ProjectDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    projectDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectIReadOnlyCollectionApiResult>>;

    /**
     */
    projectGet(initOverrides?: RequestInit): Promise<ProjectIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectGetByIdRaw(requestParameters: ProjectGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectApiResult>>;

    /**
     */
    projectGetById(id: string, initOverrides?: RequestInit): Promise<ProjectApiResult>;

    /**
     *
     * @param {string} id
     * @param {number} [page]
     * @param {number} [perpage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectGetForWorkflowByIdRaw(requestParameters: ProjectGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectPageOfApiResult>>;

    /**
     */
    projectGetForWorkflowById(id: string, page?: number, perpage?: number, initOverrides?: RequestInit): Promise<ProjectPageOfApiResult>;

    /**
     *
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectGetPageRaw(requestParameters: ProjectGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectPageOfApiResult>>;

    /**
     */
    projectGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<ProjectPageOfApiResult>;

    /**
     *
     * @param {ProjectInsertDto} [projectInsertDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectInsertRaw(requestParameters: ProjectInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectApiResult>>;

    /**
     */
    projectInsert(projectInsertDto?: ProjectInsertDto, initOverrides?: RequestInit): Promise<ProjectApiResult>;

    /**
     *
     * @param {string} [criteria]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectSearchRaw(requestParameters: ProjectSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectIReadOnlyCollectionApiResult>>;

    /**
     */
    projectSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<ProjectIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {ProjectUpdateDto} [projectUpdateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApiInterface
     */
    projectUpdateRaw(requestParameters: ProjectUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectApiResult>>;

    /**
     */
    projectUpdate(projectUpdateDto?: ProjectUpdateDto, initOverrides?: RequestInit): Promise<ProjectApiResult>;

}

/**
 *
 */
export class ProjectApi extends runtime.BaseAPI implements ProjectApiInterface {

    /**
     */
    async projectDeleteByIdRaw(requestParameters: ProjectDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Project/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async projectDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.projectDeleteByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Project/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async projectGet(initOverrides?: RequestInit): Promise<ProjectIReadOnlyCollectionApiResult> {
        const response = await this.projectGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async projectGetByIdRaw(requestParameters: ProjectGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Project/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectApiResultFromJSON(jsonValue));
    }

    /**
     */
    async projectGetById(id: string, initOverrides?: RequestInit): Promise<ProjectApiResult> {
        const response = await this.projectGetByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectGetForWorkflowByIdRaw(requestParameters: ProjectGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectPageOfApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling projectGetForWorkflowById.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perpage !== undefined) {
            queryParameters['perpage'] = requestParameters.perpage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Project/GetForWorkflow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async projectGetForWorkflowById(id: string, page?: number, perpage?: number, initOverrides?: RequestInit): Promise<ProjectPageOfApiResult> {
        const response = await this.projectGetForWorkflowByIdRaw({id: id, page: page, perpage: perpage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectGetPageRaw(requestParameters: ProjectGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectPageOfApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Project/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async projectGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<ProjectPageOfApiResult> {
        const response = await this.projectGetPageRaw({page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectInsertRaw(requestParameters: ProjectInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Project/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectInsertDtoToJSON(requestParameters.projectInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectApiResultFromJSON(jsonValue));
    }

    /**
     */
    async projectInsert(projectInsertDto?: ProjectInsertDto, initOverrides?: RequestInit): Promise<ProjectApiResult> {
        const response = await this.projectInsertRaw({projectInsertDto: projectInsertDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectSearchRaw(requestParameters: ProjectSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Project/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async projectSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<ProjectIReadOnlyCollectionApiResult> {
        const response = await this.projectSearchRaw({criteria: criteria, page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectUpdateRaw(requestParameters: ProjectUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Project/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateDtoToJSON(requestParameters.projectUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectApiResultFromJSON(jsonValue));
    }

    /**
     */
    async projectUpdate(projectUpdateDto?: ProjectUpdateDto, initOverrides?: RequestInit): Promise<ProjectApiResult> {
        const response = await this.projectUpdateRaw({projectUpdateDto: projectUpdateDto}, initOverrides);
        return await response.value();
    }

}
