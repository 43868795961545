/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Job, JobFromJSON, JobToJSON,} from './Job';

/**
 *
 * @export
 * @interface Runner
 */
export interface Runner {
    /**
     *
     * @type {string}
     * @memberof Runner
     */
    runnerId?: string;
    /**
     *
     * @type {string}
     * @memberof Runner
     */
    status?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Runner
     */
    enabled?: boolean;
    /**
     *
     * @type {string}
     * @memberof Runner
     */
    settings?: string | null;
    /**
     *
     * @type {Date}
     * @memberof Runner
     */
    dateCreated?: Date;
    /**
     *
     * @type {Date}
     * @memberof Runner
     */
    lastRun?: Date | null;
    /**
     *
     * @type {Array<Job>}
     * @memberof Runner
     */
    jobs?: Array<Job> | null;
}

export function RunnerFromJSON(json: any): Runner {
    return RunnerFromJSONTyped(json, false);
}

export function RunnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Runner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'runnerId': !exists(json, 'runnerId') ? undefined : json['runnerId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'settings': !exists(json, 'settings') ? undefined : json['settings'],
        'dateCreated': !exists(json, 'dateCreated') ? undefined : (new Date(json['dateCreated'])),
        'lastRun': !exists(json, 'lastRun') ? undefined : (json['lastRun'] === null ? null : new Date(json['lastRun'])),
        'jobs': !exists(json, 'jobs') ? undefined : (json['jobs'] === null ? null : (json['jobs'] as Array<any>).map(JobFromJSON)),
    };
}

export function RunnerToJSON(value?: Runner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'runnerId': value.runnerId,
        'status': value.status,
        'enabled': value.enabled,
        'settings': value.settings,
        'dateCreated': value.dateCreated === undefined ? undefined : (value.dateCreated.toISOString()),
        'lastRun': value.lastRun === undefined ? undefined : (value.lastRun === null ? null : value.lastRun.toISOString()),
        'jobs': value.jobs === undefined ? undefined : (value.jobs === null ? null : (value.jobs as Array<any>).map(JobToJSON)),
    };
}

