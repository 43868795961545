/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {string}
     * @memberof User
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    password?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    salt?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    roles?: string | null;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    lastLogin?: Date;
    /**
     *
     * @type {number}
     * @memberof User
     */
    failedAttempts?: number;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    unlockDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    createdDate?: Date;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'salt': !exists(json, 'salt') ? undefined : json['salt'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'lastLogin': !exists(json, 'lastLogin') ? undefined : (new Date(json['lastLogin'])),
        'failedAttempts': !exists(json, 'failedAttempts') ? undefined : json['failedAttempts'],
        'unlockDate': !exists(json, 'unlockDate') ? undefined : (json['unlockDate'] === null ? null : new Date(json['unlockDate'])),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'userId': value.userId,
        'email': value.email,
        'name': value.name,
        'password': value.password,
        'salt': value.salt,
        'roles': value.roles,
        'lastLogin': value.lastLogin === undefined ? undefined : (value.lastLogin.toISOString()),
        'failedAttempts': value.failedAttempts,
        'unlockDate': value.unlockDate === undefined ? undefined : (value.unlockDate === null ? null : value.unlockDate.toISOString()),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
    };
}

