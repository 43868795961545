/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ProjectPageOf, ProjectPageOfFromJSON, ProjectPageOfToJSON,} from './ProjectPageOf';

/**
 *
 * @export
 * @interface ProjectPageOfApiResult
 */
export interface ProjectPageOfApiResult {
    /**
     *
     * @type {boolean}
     * @memberof ProjectPageOfApiResult
     */
    success?: boolean;
    /**
     *
     * @type {ProjectPageOf}
     * @memberof ProjectPageOfApiResult
     */
    content?: ProjectPageOf;
    /**
     *
     * @type {string}
     * @memberof ProjectPageOfApiResult
     */
    message?: string | null;
}

export function ProjectPageOfApiResultFromJSON(json: any): ProjectPageOfApiResult {
    return ProjectPageOfApiResultFromJSONTyped(json, false);
}

export function ProjectPageOfApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectPageOfApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : ProjectPageOfFromJSON(json['content']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function ProjectPageOfApiResultToJSON(value?: ProjectPageOfApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'success': value.success,
        'content': ProjectPageOfToJSON(value.content),
        'message': value.message,
    };
}

