/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface JobUpdateDto
 */
export interface JobUpdateDto {
    /**
     *
     * @type {string}
     * @memberof JobUpdateDto
     */
    jobId?: string;
    /**
     *
     * @type {string}
     * @memberof JobUpdateDto
     */
    workflowId?: string;
    /**
     *
     * @type {string}
     * @memberof JobUpdateDto
     */
    runnerId?: string | null;
    /**
     *
     * @type {string}
     * @memberof JobUpdateDto
     */
    data?: string | null;
    /**
     *
     * @type {string}
     * @memberof JobUpdateDto
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof JobUpdateDto
     */
    log?: string | null;
}

export function JobUpdateDtoFromJSON(json: any): JobUpdateDto {
    return JobUpdateDtoFromJSONTyped(json, false);
}

export function JobUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'jobId': !exists(json, 'jobId') ? undefined : json['jobId'],
        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'runnerId': !exists(json, 'runnerId') ? undefined : json['runnerId'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'log': !exists(json, 'log') ? undefined : json['log'],
    };
}

export function JobUpdateDtoToJSON(value?: JobUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'jobId': value.jobId,
        'workflowId': value.workflowId,
        'runnerId': value.runnerId,
        'data': value.data,
        'status': value.status,
        'log': value.log,
    };
}

