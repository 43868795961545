/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanApiResult,
    BooleanApiResultFromJSON,
    WorkflowDataApiResult,
    WorkflowDataApiResultFromJSON,
    WorkflowDataInput,
    WorkflowDataInputToJSON,
    WorkflowDataInsertDto,
    WorkflowDataInsertDtoToJSON,
    WorkflowDataIReadOnlyCollectionApiResult,
    WorkflowDataIReadOnlyCollectionApiResultFromJSON,
    WorkflowDataListApiResult,
    WorkflowDataListApiResultFromJSON,
    WorkflowDataUpdateDto,
    WorkflowDataUpdateDtoToJSON,
} from '../models';

export interface WorkflowDataDeleteByIdRequest {
    id: string;
}

export interface WorkflowDataGetByIdRequest {
    id: string;
}

export interface WorkflowDataGetByNameRequest {
    workflowId?: string;
    name?: string;
}

export interface WorkflowDataGetForWorkflowByIdRequest {
    id: string;
}

export interface WorkflowDataGetPageRequest {
    page?: number;
    perPage?: number;
}

export interface WorkflowDataInsertRequest {
    workflowDataInsertDto?: WorkflowDataInsertDto;
}

export interface WorkflowDataSearchRequest {
    criteria?: string;
    page?: number;
    perPage?: number;
}

export interface WorkflowDataSetRequest {
    workflowDataInput?: WorkflowDataInput;
}

export interface WorkflowDataUpdateRequest {
    workflowDataUpdateDto?: WorkflowDataUpdateDto;
}

/**
 * WorkflowDataApi - interface
 *
 * @export
 * @interface WorkflowDataApiInterface
 */
export interface WorkflowDataApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataDeleteByIdRaw(requestParameters: WorkflowDataDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    workflowDataDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowDataGet(initOverrides?: RequestInit): Promise<WorkflowDataIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataGetByIdRaw(requestParameters: WorkflowDataGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>>;

    /**
     */
    workflowDataGetById(id: string, initOverrides?: RequestInit): Promise<WorkflowDataApiResult>;

    /**
     *
     * @param {string} [workflowId]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataGetByNameRaw(requestParameters: WorkflowDataGetByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>>;

    /**
     */
    workflowDataGetByName(workflowId?: string, name?: string, initOverrides?: RequestInit): Promise<WorkflowDataApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataGetForWorkflowByIdRaw(requestParameters: WorkflowDataGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataListApiResult>>;

    /**
     */
    workflowDataGetForWorkflowById(id: string, initOverrides?: RequestInit): Promise<WorkflowDataListApiResult>;

    /**
     *
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataGetPageRaw(requestParameters: WorkflowDataGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowDataGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowDataIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {WorkflowDataInsertDto} [workflowDataInsertDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataInsertRaw(requestParameters: WorkflowDataInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>>;

    /**
     */
    workflowDataInsert(workflowDataInsertDto?: WorkflowDataInsertDto, initOverrides?: RequestInit): Promise<WorkflowDataApiResult>;

    /**
     *
     * @param {string} [criteria]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataSearchRaw(requestParameters: WorkflowDataSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowDataSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowDataIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {WorkflowDataInput} [workflowDataInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataSetRaw(requestParameters: WorkflowDataSetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>>;

    /**
     */
    workflowDataSet(workflowDataInput?: WorkflowDataInput, initOverrides?: RequestInit): Promise<WorkflowDataApiResult>;

    /**
     *
     * @param {WorkflowDataUpdateDto} [workflowDataUpdateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowDataApiInterface
     */
    workflowDataUpdateRaw(requestParameters: WorkflowDataUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>>;

    /**
     */
    workflowDataUpdate(workflowDataUpdateDto?: WorkflowDataUpdateDto, initOverrides?: RequestInit): Promise<WorkflowDataApiResult>;

}

/**
 *
 */
export class WorkflowDataApi extends runtime.BaseAPI implements WorkflowDataApiInterface {

    /**
     */
    async workflowDataDeleteByIdRaw(requestParameters: WorkflowDataDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowDataDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.workflowDataDeleteByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataGet(initOverrides?: RequestInit): Promise<WorkflowDataIReadOnlyCollectionApiResult> {
        const response = await this.workflowDataGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataGetByIdRaw(requestParameters: WorkflowDataGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowDataGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataGetById(id: string, initOverrides?: RequestInit): Promise<WorkflowDataApiResult> {
        const response = await this.workflowDataGetByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataGetByNameRaw(requestParameters: WorkflowDataGetByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.workflowId !== undefined) {
            queryParameters['workflowId'] = requestParameters.workflowId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/GetByName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataGetByName(workflowId?: string, name?: string, initOverrides?: RequestInit): Promise<WorkflowDataApiResult> {
        const response = await this.workflowDataGetByNameRaw({workflowId: workflowId, name: name}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataGetForWorkflowByIdRaw(requestParameters: WorkflowDataGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataListApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowDataGetForWorkflowById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/GetForWorkflow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataListApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataGetForWorkflowById(id: string, initOverrides?: RequestInit): Promise<WorkflowDataListApiResult> {
        const response = await this.workflowDataGetForWorkflowByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataGetPageRaw(requestParameters: WorkflowDataGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowDataIReadOnlyCollectionApiResult> {
        const response = await this.workflowDataGetPageRaw({page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataInsertRaw(requestParameters: WorkflowDataInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowDataInsertDtoToJSON(requestParameters.workflowDataInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataInsert(workflowDataInsertDto?: WorkflowDataInsertDto, initOverrides?: RequestInit): Promise<WorkflowDataApiResult> {
        const response = await this.workflowDataInsertRaw({workflowDataInsertDto: workflowDataInsertDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataSearchRaw(requestParameters: WorkflowDataSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowDataIReadOnlyCollectionApiResult> {
        const response = await this.workflowDataSearchRaw({
            criteria: criteria,
            page: page,
            perPage: perPage
        }, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataSetRaw(requestParameters: WorkflowDataSetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/Set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowDataInputToJSON(requestParameters.workflowDataInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataSet(workflowDataInput?: WorkflowDataInput, initOverrides?: RequestInit): Promise<WorkflowDataApiResult> {
        const response = await this.workflowDataSetRaw({workflowDataInput: workflowDataInput}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowDataUpdateRaw(requestParameters: WorkflowDataUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowDataApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowData/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowDataUpdateDtoToJSON(requestParameters.workflowDataUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowDataApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDataUpdate(workflowDataUpdateDto?: WorkflowDataUpdateDto, initOverrides?: RequestInit): Promise<WorkflowDataApiResult> {
        const response = await this.workflowDataUpdateRaw({workflowDataUpdateDto: workflowDataUpdateDto}, initOverrides);
        return await response.value();
    }

}
