/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ComponentDefinitionListApiResult,
    ComponentDefinitionListApiResultFromJSON,
    StringApiResult,
    StringApiResultFromJSON,
    StringApiResultToJSON,
    TypeDefinitionListApiResult,
    TypeDefinitionListApiResultFromJSON,
    TypeDefinitionListApiResultToJSON,
} from '../models';

export interface SystemoauthRequest {
    id?: string;
    plugin?: string;
}

/**
 * SystemApi - interface
 *
 * @export
 * @interface SystemApiInterface
 */
export interface SystemApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    systemBackupRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    systemBackup(initOverrides?: RequestInit): Promise<void>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    systemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ComponentDefinitionListApiResult>>;

    /**
     */
    systemDefinitions(initOverrides?: RequestInit): Promise<ComponentDefinitionListApiResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    systemTypeDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TypeDefinitionListApiResult>>;

    /**
     */
    systemTypeDefinitions(initOverrides?: RequestInit): Promise<TypeDefinitionListApiResult>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [plugin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    systemoauthRaw(requestParameters: SystemoauthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     */
    systemoauth(id?: string, plugin?: string, initOverrides?: RequestInit): Promise<void>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    systemoauthreturnRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StringApiResult>>;

    /**
     */
    systemoauthreturn(initOverrides?: RequestInit): Promise<StringApiResult>;

}

/**
 *
 */
export class SystemApi extends runtime.BaseAPI implements SystemApiInterface {

    /**
     */
    async systemBackupRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/System/Backup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async systemBackup(initOverrides?: RequestInit): Promise<void> {
        await this.systemBackupRaw(initOverrides);
    }

    /**
     */
    async systemDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ComponentDefinitionListApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/System/Definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ComponentDefinitionListApiResultFromJSON(jsonValue));
    }

    /**
     */
    async systemDefinitions(initOverrides?: RequestInit): Promise<ComponentDefinitionListApiResult> {
        const response = await this.systemDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async systemTypeDefinitionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TypeDefinitionListApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/System/TypeDefinitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeDefinitionListApiResultFromJSON(jsonValue));
    }

    /**
     */
    async systemTypeDefinitions(initOverrides?: RequestInit): Promise<TypeDefinitionListApiResult> {
        const response = await this.systemTypeDefinitionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async systemoauthRaw(requestParameters: SystemoauthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.plugin !== undefined) {
            queryParameters['plugin'] = requestParameters.plugin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/System/oauth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async systemoauth(id?: string, plugin?: string, initOverrides?: RequestInit): Promise<void> {
        await this.systemoauthRaw({id: id, plugin: plugin}, initOverrides);
    }

    /**
     */
    async systemoauthreturnRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<StringApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/System/oauthreturn`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringApiResultFromJSON(jsonValue));
    }

    /**
     */
    async systemoauthreturn(initOverrides?: RequestInit): Promise<StringApiResult> {
        const response = await this.systemoauthreturnRaw(initOverrides);
        return await response.value();
    }

}
