/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Workflow, WorkflowFromJSON, WorkflowToJSON,} from './Workflow';

/**
 *
 * @export
 * @interface Project
 */
export interface Project {
    /**
     *
     * @type {string}
     * @memberof Project
     */
    projectId?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    name?: string | null;
    /**
     *
     * @type {Date}
     * @memberof Project
     */
    dateCreated?: Date;
    /**
     *
     * @type {Array<Workflow>}
     * @memberof Project
     */
    workflows?: Array<Workflow> | null;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'dateCreated': !exists(json, 'dateCreated') ? undefined : (new Date(json['dateCreated'])),
        'workflows': !exists(json, 'workflows') ? undefined : (json['workflows'] === null ? null : (json['workflows'] as Array<any>).map(WorkflowFromJSON)),
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'projectId': value.projectId,
        'name': value.name,
        'dateCreated': value.dateCreated === undefined ? undefined : (value.dateCreated.toISOString()),
        'workflows': value.workflows === undefined ? undefined : (value.workflows === null ? null : (value.workflows as Array<any>).map(WorkflowToJSON)),
    };
}

