/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface WorkflowHistory
 */
export interface WorkflowHistory {
    /**
     *
     * @type {string}
     * @memberof WorkflowHistory
     */
    workflowHistoryId?: string;
    /**
     *
     * @type {string}
     * @memberof WorkflowHistory
     */
    workflowId?: string;
    /**
     *
     * @type {string}
     * @memberof WorkflowHistory
     */
    workflowJson?: string | null;
    /**
     *
     * @type {Date}
     * @memberof WorkflowHistory
     */
    dateSaved?: Date;
}

export function WorkflowHistoryFromJSON(json: any): WorkflowHistory {
    return WorkflowHistoryFromJSONTyped(json, false);
}

export function WorkflowHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'workflowHistoryId': !exists(json, 'workflowHistoryId') ? undefined : json['workflowHistoryId'],
        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'workflowJson': !exists(json, 'workflowJson') ? undefined : json['workflowJson'],
        'dateSaved': !exists(json, 'dateSaved') ? undefined : (new Date(json['dateSaved'])),
    };
}

export function WorkflowHistoryToJSON(value?: WorkflowHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'workflowHistoryId': value.workflowHistoryId,
        'workflowId': value.workflowId,
        'workflowJson': value.workflowJson,
        'dateSaved': value.dateSaved === undefined ? undefined : (value.dateSaved.toISOString()),
    };
}

