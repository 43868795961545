
import {getCurrentInstance, ref} from 'vue';
import {useProjectStore} from '@/store/projectStore';
import {storeToRefs} from "pinia";
import {useRouter} from 'vue-router';
import {PageState} from 'primevue/paginator';
import {useToast} from "primevue/usetoast";
import {Project} from '@/models/'

export default {
  name: "projects-table",
  setup(props) {
    const internalInstance = getCurrentInstance()
    const store = useProjectStore();
    const router = useRouter();
    const toast = useToast();
    const showDialog = ref(false);
    const {
      loading,
      projects,
      page,
      pageSize,
      totalRecords
    } = storeToRefs(store);
    const newProject = ref({} as Project)

    function getProjects() {
      store.getProjects();
    }

    getProjects();

    function changePage(event: PageState) {
      page.value = event.page + 1;
      pageSize.value = event.rows;
      getProjects();
    }

    function viewProject(id: string) {
      router.push({name: 'workflows', params: {id: id}});
    }

    function saveProject() {
      if (newProject.value.name != "") {
        store.save(newProject.value).then(x => {
          newProject.value = {};
          showDialog.value = false;
          getProjects();
        });
      }
    }

    return {
      changePage,
      viewProject,
      saveProject,

      newProject,
      showDialog,
      loading,
      projects,
      page,
      pageSize,
      totalRecords
    }
  }
}
