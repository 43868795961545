/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {WorkflowData, WorkflowDataFromJSON, WorkflowDataToJSON,} from './WorkflowData';
import {WorkflowHistory, WorkflowHistoryFromJSON, WorkflowHistoryToJSON,} from './WorkflowHistory';
import {WorkflowScript, WorkflowScriptFromJSON, WorkflowScriptToJSON,} from './WorkflowScript';

/**
 *
 * @export
 * @interface Workflow
 */
export interface Workflow {
    /**
     *
     * @type {string}
     * @memberof Workflow
     */
    workflowId?: string;
    /**
     *
     * @type {string}
     * @memberof Workflow
     */
    projectId?: string;
    /**
     *
     * @type {string}
     * @memberof Workflow
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Workflow
     */
    workflowJson?: string | null;
    /**
     *
     * @type {string}
     * @memberof Workflow
     */
    schedule?: string | null;
    /**
     *
     * @type {Date}
     * @memberof Workflow
     */
    lastSchedule?: Date | null;
    /**
     *
     * @type {string}
     * @memberof Workflow
     */
    webHookId?: string | null;
    /**
     *
     * @type {Date}
     * @memberof Workflow
     */
    dateCreated?: Date;
    /**
     *
     * @type {Date}
     * @memberof Workflow
     */
    dateModified?: Date;
    /**
     *
     * @type {boolean}
     * @memberof Workflow
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Workflow
     */
    debugMode?: boolean;
    /**
     *
     * @type {Array<WorkflowData>}
     * @memberof Workflow
     */
    data?: Array<WorkflowData> | null;
    /**
     *
     * @type {Array<WorkflowScript>}
     * @memberof Workflow
     */
    scripts?: Array<WorkflowScript> | null;
    /**
     *
     * @type {Array<WorkflowHistory>}
     * @memberof Workflow
     */
    history?: Array<WorkflowHistory> | null;
}

export function WorkflowFromJSON(json: any): Workflow {
    return WorkflowFromJSONTyped(json, false);
}

export function WorkflowFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workflow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'workflowJson': !exists(json, 'workflowJson') ? undefined : json['workflowJson'],
        'schedule': !exists(json, 'schedule') ? undefined : json['schedule'],
        'lastSchedule': !exists(json, 'lastSchedule') ? undefined : (json['lastSchedule'] === null ? null : new Date(json['lastSchedule'])),
        'webHookId': !exists(json, 'webHookId') ? undefined : json['webHookId'],
        'dateCreated': !exists(json, 'dateCreated') ? undefined : (new Date(json['dateCreated'])),
        'dateModified': !exists(json, 'dateModified') ? undefined : (new Date(json['dateModified'])),
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'debugMode': !exists(json, 'debugMode') ? undefined : json['debugMode'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(WorkflowDataFromJSON)),
        'scripts': !exists(json, 'scripts') ? undefined : (json['scripts'] === null ? null : (json['scripts'] as Array<any>).map(WorkflowScriptFromJSON)),
        'history': !exists(json, 'history') ? undefined : (json['history'] === null ? null : (json['history'] as Array<any>).map(WorkflowHistoryFromJSON)),
    };
}

export function WorkflowToJSON(value?: Workflow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'workflowId': value.workflowId,
        'projectId': value.projectId,
        'name': value.name,
        'workflowJson': value.workflowJson,
        'schedule': value.schedule,
        'lastSchedule': value.lastSchedule === undefined ? undefined : (value.lastSchedule === null ? null : value.lastSchedule.toISOString()),
        'webHookId': value.webHookId,
        'dateCreated': value.dateCreated === undefined ? undefined : (value.dateCreated.toISOString()),
        'dateModified': value.dateModified === undefined ? undefined : (value.dateModified.toISOString()),
        'enabled': value.enabled,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(WorkflowDataToJSON)),
        'scripts': value.scripts === undefined ? undefined : (value.scripts === null ? null : (value.scripts as Array<any>).map(WorkflowScriptToJSON)),
        'history': value.history === undefined ? undefined : (value.history === null ? null : (value.history as Array<any>).map(WorkflowHistoryToJSON)),
    };
}

