/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {AuthorizationApiResult, AuthorizationApiResultFromJSON,} from '../models';

export interface AuthorizeRequest {
    email?: string;
    password?: string;
}

export interface RegisterRequest {
    userId?: string;
    email?: string;
    name?: string;
    password?: string;
    salt?: string;
    roles?: string;
    lastLogin?: Date;
    failedAttempts?: number;
    unlockDate?: Date;
    createdDate?: Date;
}

/**
 * TokenApi - interface
 *
 * @export
 * @interface TokenApiInterface
 */
export interface TokenApiInterface {
    /**
     *
     * @param {string} [email]
     * @param {string} [password]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApiInterface
     */
    authorizeRaw(requestParameters: AuthorizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizationApiResult>>;

    /**
     */
    authorize(email?: string, password?: string, initOverrides?: RequestInit): Promise<AuthorizationApiResult>;

    /**
     *
     * @param {string} [userId]
     * @param {string} [email]
     * @param {string} [name]
     * @param {string} [password]
     * @param {string} [salt]
     * @param {string} [roles]
     * @param {Date} [lastLogin]
     * @param {number} [failedAttempts]
     * @param {Date} [unlockDate]
     * @param {Date} [createdDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApiInterface
     */
    registerRaw(requestParameters: RegisterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizationApiResult>>;

    /**
     */
    register(userId?: string, email?: string, name?: string, password?: string, salt?: string, roles?: string, lastLogin?: Date, failedAttempts?: number, unlockDate?: Date, createdDate?: Date, initOverrides?: RequestInit): Promise<AuthorizationApiResult>;

}

/**
 *
 */
export class TokenApi extends runtime.BaseAPI implements TokenApiInterface {

    /**
     */
    async authorizeRaw(requestParameters: AuthorizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizationApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['Email'] = requestParameters.email;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Authorize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationApiResultFromJSON(jsonValue));
    }

    /**
     */
    async authorize(email?: string, password?: string, initOverrides?: RequestInit): Promise<AuthorizationApiResult> {
        const response = await this.authorizeRaw({email: email, password: password}, initOverrides);
        return await response.value();
    }

    /**
     */
    async registerRaw(requestParameters: RegisterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizationApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['UserId'] = requestParameters.userId;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['Email'] = requestParameters.email;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['Password'] = requestParameters.password;
        }

        if (requestParameters.salt !== undefined) {
            queryParameters['Salt'] = requestParameters.salt;
        }

        if (requestParameters.roles !== undefined) {
            queryParameters['Roles'] = requestParameters.roles;
        }

        if (requestParameters.lastLogin !== undefined) {
            queryParameters['LastLogin'] = (requestParameters.lastLogin as any).toISOString();
        }

        if (requestParameters.failedAttempts !== undefined) {
            queryParameters['FailedAttempts'] = requestParameters.failedAttempts;
        }

        if (requestParameters.unlockDate !== undefined) {
            queryParameters['UnlockDate'] = (requestParameters.unlockDate as any).toISOString();
        }

        if (requestParameters.createdDate !== undefined) {
            queryParameters['CreatedDate'] = (requestParameters.createdDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Register`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationApiResultFromJSON(jsonValue));
    }

    /**
     */
    async register(userId?: string, email?: string, name?: string, password?: string, salt?: string, roles?: string, lastLogin?: Date, failedAttempts?: number, unlockDate?: Date, createdDate?: Date, initOverrides?: RequestInit): Promise<AuthorizationApiResult> {
        const response = await this.registerRaw({
            userId: userId,
            email: email,
            name: name,
            password: password,
            salt: salt,
            roles: roles,
            lastLogin: lastLogin,
            failedAttempts: failedAttempts,
            unlockDate: unlockDate,
            createdDate: createdDate
        }, initOverrides);
        return await response.value();
    }

}
