/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface StringApiResult
 */
export interface StringApiResult {
    /**
     *
     * @type {boolean}
     * @memberof StringApiResult
     */
    success?: boolean;
    /**
     *
     * @type {string}
     * @memberof StringApiResult
     */
    content?: string | null;
    /**
     *
     * @type {string}
     * @memberof StringApiResult
     */
    message?: string | null;
}

export function StringApiResultFromJSON(json: any): StringApiResult {
    return StringApiResultFromJSONTyped(json, false);
}

export function StringApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function StringApiResultToJSON(value?: StringApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'success': value.success,
        'content': value.content,
        'message': value.message,
    };
}

