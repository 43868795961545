/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Job, JobFromJSON, JobToJSON,} from './Job';

/**
 *
 * @export
 * @interface JobApiResult
 */
export interface JobApiResult {
    /**
     *
     * @type {boolean}
     * @memberof JobApiResult
     */
    success?: boolean;
    /**
     *
     * @type {Job}
     * @memberof JobApiResult
     */
    content?: Job;
    /**
     *
     * @type {string}
     * @memberof JobApiResult
     */
    message?: string | null;
}

export function JobApiResultFromJSON(json: any): JobApiResult {
    return JobApiResultFromJSONTyped(json, false);
}

export function JobApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : JobFromJSON(json['content']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function JobApiResultToJSON(value?: JobApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'success': value.success,
        'content': JobToJSON(value.content),
        'message': value.message,
    };
}

