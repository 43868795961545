/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {ScheduleStatus, ScheduleStatusFromJSON, ScheduleStatusToJSON,} from './ScheduleStatus';

/**
 *
 * @export
 * @interface ScheduleLog
 */
export interface ScheduleLog {
    /**
     *
     * @type {string}
     * @memberof ScheduleLog
     */
    name?: string | null;
    /**
     *
     * @type {Date}
     * @memberof ScheduleLog
     */
    nextStart?: Date;
    /**
     *
     * @type {Date}
     * @memberof ScheduleLog
     */
    lastRun?: Date | null;
    /**
     *
     * @type {string}
     * @memberof ScheduleLog
     */
    message?: string | null;
    /**
     *
     * @type {ScheduleStatus}
     * @memberof ScheduleLog
     */
    status?: ScheduleStatus;
}

export function ScheduleLogFromJSON(json: any): ScheduleLog {
    return ScheduleLogFromJSONTyped(json, false);
}

export function ScheduleLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'name': !exists(json, 'name') ? undefined : json['name'],
        'nextStart': !exists(json, 'nextStart') ? undefined : (new Date(json['nextStart'])),
        'lastRun': !exists(json, 'lastRun') ? undefined : (json['lastRun'] === null ? null : new Date(json['lastRun'])),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'status': !exists(json, 'status') ? undefined : ScheduleStatusFromJSON(json['status']),
    };
}

export function ScheduleLogToJSON(value?: ScheduleLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'name': value.name,
        'nextStart': value.nextStart === undefined ? undefined : (value.nextStart.toISOString()),
        'lastRun': value.lastRun === undefined ? undefined : (value.lastRun === null ? null : value.lastRun.toISOString()),
        'message': value.message,
        'status': ScheduleStatusToJSON(value.status),
    };
}

