/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface ProjectInsertDto
 */
export interface ProjectInsertDto {
    /**
     *
     * @type {string}
     * @memberof ProjectInsertDto
     */
    name?: string | null;
}

export function ProjectInsertDtoFromJSON(json: any): ProjectInsertDto {
    return ProjectInsertDtoFromJSONTyped(json, false);
}

export function ProjectInsertDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInsertDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function ProjectInsertDtoToJSON(value?: ProjectInsertDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'name': value.name,
    };
}

