/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Project, ProjectFromJSON, ProjectToJSON,} from './Project';

/**
 *
 * @export
 * @interface ProjectPageOf
 */
export interface ProjectPageOf {
    /**
     *
     * @type {number}
     * @memberof ProjectPageOf
     */
    totalRecords?: number;
    /**
     *
     * @type {Array<Project>}
     * @memberof ProjectPageOf
     */
    data?: Array<Project> | null;
}

export function ProjectPageOfFromJSON(json: any): ProjectPageOf {
    return ProjectPageOfFromJSONTyped(json, false);
}

export function ProjectPageOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectPageOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(ProjectFromJSON)),
    };
}

export function ProjectPageOfToJSON(value?: ProjectPageOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'totalRecords': value.totalRecords,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(ProjectToJSON)),
    };
}

