/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface InputJobLog
 */
export interface InputJobLog {
    /**
     *
     * @type {string}
     * @memberof InputJobLog
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof InputJobLog
     */
    log?: string | null;
    /**
     *
     * @type {string}
     * @memberof InputJobLog
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputJobLog
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InputJobLog
     */
    componentId?: string | null;
}

export function InputJobLogFromJSON(json: any): InputJobLog {
    return InputJobLogFromJSONTyped(json, false);
}

export function InputJobLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputJobLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'log': !exists(json, 'log') ? undefined : json['log'],
        'detail': !exists(json, 'detail') ? undefined : json['detail'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'componentId': !exists(json, 'componentId') ? undefined : json['componentId'],
    };
}

export function InputJobLogToJSON(value?: InputJobLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'log': value.log,
        'detail': value.detail,
        'status': value.status,
        'componentId': value.componentId,
    };
}

