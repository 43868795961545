import {defineStore} from "pinia";
import {BASE_PATH} from "@/assets/consts";
import {useLocalStorage} from "@vueuse/core";
import {ref} from "vue";
import {TokenApi} from "@/models/";
import {Configuration} from "@/models/runtime";
import {useToast} from 'primevue/usetoast';

export const useAuthStore = defineStore({
    id: "auth",
    state: () => {
        const user = useLocalStorage("userStorage",
            {
                id: "" as string | null | undefined,
                email: "" as string | null | undefined,
                name: "" as string | null | undefined,
                role: "" as string | null | undefined,
                token: "" as string | null | undefined,
                //expires:  Date | null | undefined
            })
        const api = ref(new TokenApi(new Configuration({
            basePath: BASE_PATH,
            headers: {"Authorization": "Bearer " + user.value.token}
        })));
        const isLoggedIn = ref(false);
        return {
            api,
            isLoggedIn,
            user,
            toast: useToast()
        };
    },
    getters: {
        isAuthorised(): boolean {
            if (this.user.token != "") {
                return true;
            }
            return false;
        },
        role(): string | null | undefined {
            return this.user.role;
        },
        fullname(): string | null | undefined {
            return this.user.name;
        },
        email(): string | null | undefined {
            return this.user.email;
        },
        id(): string | null | undefined {
            return this.user.id;
        }
    },
    actions: {
        async authorise(email: string, password: string) {
            return await this.api.authorize(email, password).then(async response => {
                if (response.success) {
                    this.user.token = response.content?.accessToken;
                    // this.user.expires = response.content?.expires;
                    this.user.email = email;
                    this.user.id = response.content?.userId;
                    this.user.name = response.content?.name;
                    this.user.role = response.content?.role;
                    this.api = new TokenApi(new Configuration({
                        basePath: BASE_PATH,
                        headers: {"Authorization": "Bearer " + response.content?.accessToken}
                    }));
                    this.isLoggedIn = true;
                    return true;
                } else {
                    this.toast.add({severity: "error", detail: response.message, summary: "Login error", life: 3000})
                    return false;
                }
            }).catch((x) => {
                console.log(x);
                this.toast.add({severity: "error", detail: x.message, summary: "Login error", life: 3000})
                return false;
            });
        },
        logout() {
            this.isLoggedIn = false;
            this.user = {id: "", email: "", name: "", role: "", token: ""}
        }
    }
})
