/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {WorkflowData, WorkflowDataFromJSON, WorkflowDataToJSON,} from './WorkflowData';

/**
 *
 * @export
 * @interface WorkflowDataListApiResult
 */
export interface WorkflowDataListApiResult {
    /**
     *
     * @type {boolean}
     * @memberof WorkflowDataListApiResult
     */
    success?: boolean;
    /**
     *
     * @type {Array<WorkflowData>}
     * @memberof WorkflowDataListApiResult
     */
    content?: Array<WorkflowData> | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowDataListApiResult
     */
    message?: string | null;
}

export function WorkflowDataListApiResultFromJSON(json: any): WorkflowDataListApiResult {
    return WorkflowDataListApiResultFromJSONTyped(json, false);
}

export function WorkflowDataListApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowDataListApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : (json['content'] === null ? null : (json['content'] as Array<any>).map(WorkflowDataFromJSON)),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function WorkflowDataListApiResultToJSON(value?: WorkflowDataListApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'success': value.success,
        'content': value.content === undefined ? undefined : (value.content === null ? null : (value.content as Array<any>).map(WorkflowDataToJSON)),
        'message': value.message,
    };
}

