/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface WorkflowInsertDto
 */
export interface WorkflowInsertDto {
    /**
     *
     * @type {string}
     * @memberof WorkflowInsertDto
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowInsertDto
     */
    workflowJson?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowInsertDto
     */
    schedule?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowInsertDto
     */
    webHookId?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowInsertDto
     */
    projectId?: string;
}

export function WorkflowInsertDtoFromJSON(json: any): WorkflowInsertDto {
    return WorkflowInsertDtoFromJSONTyped(json, false);
}

export function WorkflowInsertDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowInsertDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'name': !exists(json, 'name') ? undefined : json['name'],
        'workflowJson': !exists(json, 'workflowJson') ? undefined : json['workflowJson'],
        'schedule': !exists(json, 'schedule') ? undefined : json['schedule'],
        'webHookId': !exists(json, 'webHookId') ? undefined : json['webHookId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
    };
}

export function WorkflowInsertDtoToJSON(value?: WorkflowInsertDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'name': value.name,
        'workflowJson': value.workflowJson,
        'schedule': value.schedule,
        'webHookId': value.webHookId,
        'projectId': value.projectId,
    };
}

