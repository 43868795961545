/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TypeDefinition,
    TypeDefinitionFromJSON,
    TypeDefinitionFromJSONTyped,
    TypeDefinitionToJSON,
} from './TypeDefinition';

/**
 * 
 * @export
 * @interface TypeDefinitionListApiResult
 */
export interface TypeDefinitionListApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof TypeDefinitionListApiResult
     */
    success?: boolean;
    /**
     * 
     * @type {Array<TypeDefinition>}
     * @memberof TypeDefinitionListApiResult
     */
    content?: Array<TypeDefinition> | null;
    /**
     * 
     * @type {string}
     * @memberof TypeDefinitionListApiResult
     */
    message?: string | null;
}

export function TypeDefinitionListApiResultFromJSON(json: any): TypeDefinitionListApiResult {
    return TypeDefinitionListApiResultFromJSONTyped(json, false);
}

export function TypeDefinitionListApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeDefinitionListApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : (json['content'] === null ? null : (json['content'] as Array<any>).map(TypeDefinitionFromJSON)),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function TypeDefinitionListApiResultToJSON(value?: TypeDefinitionListApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'content': value.content === undefined ? undefined : (value.content === null ? null : (value.content as Array<any>).map(TypeDefinitionToJSON)),
        'message': value.message,
    };
}

