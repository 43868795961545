/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanApiResult,
    BooleanApiResultFromJSON,
    InputJobLog,
    InputJobLogToJSON,
    JobApiResult,
    JobApiResultFromJSON,
    JobInsertDto,
    JobInsertDtoToJSON,
    JobIReadOnlyCollectionApiResult,
    JobIReadOnlyCollectionApiResultFromJSON,
    JobPageOfApiResult,
    JobPageOfApiResultFromJSON,
    JobUpdateDto,
    JobUpdateDtoToJSON,
    JobWorkflowApiResult,
    JobWorkflowApiResultFromJSON,
} from '../models';

export interface JobDeleteByIdRequest {
    id: string;
}

export interface JobGetByIdRequest {
    id: string;
}

export interface JobGetForWorkflowByIdRequest {
    id: string;
    page?: number;
    perpage?: number;
}

export interface JobGetNextByIdRequest {
    id: string;
}

export interface JobGetPageRequest {
    page?: number;
    perPage?: number;
}

export interface JobInsertRequest {
    jobInsertDto?: JobInsertDto;
}

export interface JobLogRequest {
    inputJobLog?: InputJobLog;
}

export interface JobLog0Request {
    inputJobLog?: InputJobLog;
}

export interface JobRerunRequest {
    id?: string;
}

export interface JobSearchRequest {
    criteria?: string;
    page?: number;
    perPage?: number;
}

export interface JobUpdateRequest {
    jobUpdateDto?: JobUpdateDto;
}

/**
 * JobApi - interface
 *
 * @export
 * @interface JobApiInterface
 */
export interface JobApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobDeleteByIdRaw(requestParameters: JobDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    jobDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobIReadOnlyCollectionApiResult>>;

    /**
     */
    jobGet(initOverrides?: RequestInit): Promise<JobIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobGetByIdRaw(requestParameters: JobGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>>;

    /**
     */
    jobGetById(id: string, initOverrides?: RequestInit): Promise<JobApiResult>;

    /**
     *
     * @param {string} id
     * @param {number} [page]
     * @param {number} [perpage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobGetForWorkflowByIdRaw(requestParameters: JobGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobPageOfApiResult>>;

    /**
     */
    jobGetForWorkflowById(id: string, page?: number, perpage?: number, initOverrides?: RequestInit): Promise<JobPageOfApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobGetNextByIdRaw(requestParameters: JobGetNextByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobWorkflowApiResult>>;

    /**
     */
    jobGetNextById(id: string, initOverrides?: RequestInit): Promise<JobWorkflowApiResult>;

    /**
     *
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobGetPageRaw(requestParameters: JobGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobPageOfApiResult>>;

    /**
     */
    jobGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<JobPageOfApiResult>;

    /**
     *
     * @param {JobInsertDto} [jobInsertDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobInsertRaw(requestParameters: JobInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>>;

    /**
     */
    jobInsert(jobInsertDto?: JobInsertDto, initOverrides?: RequestInit): Promise<JobApiResult>;

    /**
     *
     * @param {InputJobLog} [inputJobLog]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobLogRaw(requestParameters: JobLogRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>>;

    /**
     */
    jobLog(inputJobLog?: InputJobLog, initOverrides?: RequestInit): Promise<JobApiResult>;

    /**
     *
     * @param {InputJobLog} [inputJobLog]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobLog_1Raw(requestParameters: JobLog0Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>>;

    /**
     */
    jobLog_1(inputJobLog?: InputJobLog, initOverrides?: RequestInit): Promise<JobApiResult>;

    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobRerunRaw(requestParameters: JobRerunRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>>;

    /**
     */
    jobRerun(id?: string, initOverrides?: RequestInit): Promise<JobApiResult>;

    /**
     *
     * @param {string} [criteria]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobSearchRaw(requestParameters: JobSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobIReadOnlyCollectionApiResult>>;

    /**
     */
    jobSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<JobIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {JobUpdateDto} [jobUpdateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApiInterface
     */
    jobUpdateRaw(requestParameters: JobUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>>;

    /**
     */
    jobUpdate(jobUpdateDto?: JobUpdateDto, initOverrides?: RequestInit): Promise<JobApiResult>;

}

/**
 *
 */
export class JobApi extends runtime.BaseAPI implements JobApiInterface {

    /**
     */
    async jobDeleteByIdRaw(requestParameters: JobDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling jobDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.jobDeleteByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobGet(initOverrides?: RequestInit): Promise<JobIReadOnlyCollectionApiResult> {
        const response = await this.jobGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async jobGetByIdRaw(requestParameters: JobGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling jobGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobGetById(id: string, initOverrides?: RequestInit): Promise<JobApiResult> {
        const response = await this.jobGetByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobGetForWorkflowByIdRaw(requestParameters: JobGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobPageOfApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling jobGetForWorkflowById.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perpage !== undefined) {
            queryParameters['perpage'] = requestParameters.perpage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/GetForWorkflow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobGetForWorkflowById(id: string, page?: number, perpage?: number, initOverrides?: RequestInit): Promise<JobPageOfApiResult> {
        const response = await this.jobGetForWorkflowByIdRaw({id: id, page: page, perpage: perpage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobGetNextByIdRaw(requestParameters: JobGetNextByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobWorkflowApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling jobGetNextById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/GetNext/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobWorkflowApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobGetNextById(id: string, initOverrides?: RequestInit): Promise<JobWorkflowApiResult> {
        const response = await this.jobGetNextByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobGetPageRaw(requestParameters: JobGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobPageOfApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<JobPageOfApiResult> {
        const response = await this.jobGetPageRaw({page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobInsertRaw(requestParameters: JobInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobInsertDtoToJSON(requestParameters.jobInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobInsert(jobInsertDto?: JobInsertDto, initOverrides?: RequestInit): Promise<JobApiResult> {
        const response = await this.jobInsertRaw({jobInsertDto: jobInsertDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobLogRaw(requestParameters: JobLogRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Log`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InputJobLogToJSON(requestParameters.inputJobLog),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobLog(inputJobLog?: InputJobLog, initOverrides?: RequestInit): Promise<JobApiResult> {
        const response = await this.jobLogRaw({inputJobLog: inputJobLog}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobLog_1Raw(requestParameters: JobLog0Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Log`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputJobLogToJSON(requestParameters.inputJobLog),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobLog_1(inputJobLog?: InputJobLog, initOverrides?: RequestInit): Promise<JobApiResult> {
        const response = await this.jobLog_1Raw({inputJobLog: inputJobLog}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobRerunRaw(requestParameters: JobRerunRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Rerun`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobRerun(id?: string, initOverrides?: RequestInit): Promise<JobApiResult> {
        const response = await this.jobRerunRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobSearchRaw(requestParameters: JobSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<JobIReadOnlyCollectionApiResult> {
        const response = await this.jobSearchRaw({criteria: criteria, page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async jobUpdateRaw(requestParameters: JobUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Job/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: JobUpdateDtoToJSON(requestParameters.jobUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobApiResultFromJSON(jsonValue));
    }

    /**
     */
    async jobUpdate(jobUpdateDto?: JobUpdateDto, initOverrides?: RequestInit): Promise<JobApiResult> {
        const response = await this.jobUpdateRaw({jobUpdateDto: jobUpdateDto}, initOverrides);
        return await response.value();
    }

}
