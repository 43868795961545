/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface WorkflowDataUpdateDto
 */
export interface WorkflowDataUpdateDto {
    /**
     *
     * @type {string}
     * @memberof WorkflowDataUpdateDto
     */
    workflowDataId?: string;
    /**
     *
     * @type {string}
     * @memberof WorkflowDataUpdateDto
     */
    workflowId?: string;
    /**
     *
     * @type {string}
     * @memberof WorkflowDataUpdateDto
     */
    key?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowDataUpdateDto
     */
    value?: string | null;
}

export function WorkflowDataUpdateDtoFromJSON(json: any): WorkflowDataUpdateDto {
    return WorkflowDataUpdateDtoFromJSONTyped(json, false);
}

export function WorkflowDataUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowDataUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'workflowDataId': !exists(json, 'workflowDataId') ? undefined : json['workflowDataId'],
        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function WorkflowDataUpdateDtoToJSON(value?: WorkflowDataUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'workflowDataId': value.workflowDataId,
        'workflowId': value.workflowId,
        'key': value.key,
        'value': value.value,
    };
}

