/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    password?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    roles?: string | null;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'email': value.email,
        'name': value.name,
        'password': value.password,
        'roles': value.roles,
    };
}

