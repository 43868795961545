/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface WorkflowUpdateDto
 */
export interface WorkflowUpdateDto {
    /**
     *
     * @type {string}
     * @memberof WorkflowUpdateDto
     */
    workflowId?: string;
    /**
     *
     * @type {string}
     * @memberof WorkflowUpdateDto
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowUpdateDto
     */
    workflowJson?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowUpdateDto
     */
    schedule?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowUpdateDto
     */
    webHookId?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof WorkflowUpdateDto
     */
    enabled?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof WorkflowUpdateDto
     */
    debugMode?: boolean;
    /**
     *
     * @type {string}
     * @memberof WorkflowUpdateDto
     */
    projectId?: string;
    /**
     *
     * @type {Date}
     * @memberof WorkflowUpdateDto
     */
    lastSchedule?: Date | null;
}

export function WorkflowUpdateDtoFromJSON(json: any): WorkflowUpdateDto {
    return WorkflowUpdateDtoFromJSONTyped(json, false);
}

export function WorkflowUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'workflowJson': !exists(json, 'workflowJson') ? undefined : json['workflowJson'],
        'schedule': !exists(json, 'schedule') ? undefined : json['schedule'],
        'webHookId': !exists(json, 'webHookId') ? undefined : json['webHookId'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'lastSchedule': !exists(json, 'lastSchedule') ? undefined : (json['lastSchedule'] === null ? null : new Date(json['lastSchedule'])),
    };
}

export function WorkflowUpdateDtoToJSON(value?: WorkflowUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'workflowId': value.workflowId,
        'name': value.name,
        'workflowJson': value.workflowJson,
        'schedule': value.schedule,
        'webHookId': value.webHookId,
        'enabled': value.enabled,
        "debugMode": value.debugMode,
        'projectId': value.projectId,
        'lastSchedule': value.lastSchedule === undefined ? undefined : (value.lastSchedule === null ? null : value.lastSchedule.toISOString()),
    };
}

