import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import {createPinia} from "pinia";
import moment from "moment";

import "primeflex/primeflex.css"
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';

import {InstallCodemirro} from "codemirror-editor-vue3";
import JsonSchemaEditor from 'json-schema-editor-vue3'
import 'json-schema-editor-vue3/lib/json-schema-editor-vue3.css'
//  IMPORT ALL PRIMEVUE COMPONENTS GLOBALLY (TODO: Remove unused before production)
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import AutoComplete from 'primevue/autocomplete';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BlockUI from 'primevue/blockui';
import Breadcrumb from 'primevue/breadcrumb';
import BreadcrumbItem from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import CascadeSelect from 'primevue/cascadeselect';
import CascadeSelectSub from 'primevue/cascadeselect';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ContextMenu from 'primevue/contextmenu';
import ContextMenuSub from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import DeferredContent from 'primevue/deferredcontent';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dock from 'primevue/dock';
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import Portal from 'primevue/portal';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import Row from 'primevue/row';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import SelectButton from 'primevue/selectbutton';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Terminal from 'primevue/terminal';
import Textarea from 'primevue/textarea';
import TieredMenu from 'primevue/tieredmenu';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToggleButton from 'primevue/togglebutton';
import Toolbar from 'primevue/toolbar';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import VirtualScroller from 'primevue/virtualscroller';
import Tooltip from 'primevue/tooltip';


moment.locale("en");

const pinia = createPinia();
const Vue = createApp(App);
Vue.use(pinia)
    .use(router)
    .use(ToastService)
    .use(InstallCodemirro)
    .use(JsonSchemaEditor)
    .use(PrimeVue, {ripple: true});
/*Vue.config.errorHandler = (err) => {
    console.log(err);
};*/
Vue.config.globalProperties.$moment = moment;
Vue.config.globalProperties.$toast = ToastService;


Vue.component('ui-accordion', Accordion);
Vue.component('ui-accordiontab', AccordionTab);
Vue.component('ui-autocomplete', AutoComplete);
Vue.component('ui-avatar', Avatar);
Vue.component('ui-avatargroup', AvatarGroup);
Vue.component('ui-badge', Badge);
Vue.component('ui-blockui', BlockUI);
Vue.component('ui-breadcrumb', Breadcrumb);
Vue.component('ui-breadcrumbitem', BreadcrumbItem);
Vue.component('ui-button', Button);
Vue.component('ui-calendar', Calendar);
Vue.component('ui-card', Card);
Vue.component('ui-carousel', Carousel);
Vue.component('ui-cascadeselect', CascadeSelect);
Vue.component('ui-cascadeselectsub', CascadeSelectSub);
Vue.component('ui-chart', Chart);
Vue.component('ui-checkbox', Checkbox);
Vue.component('ui-chip', Chip);
Vue.component('ui-chips', Chips);
Vue.component('ui-colorpicker', ColorPicker);
Vue.component('ui-column', Column);
Vue.component('ui-columngroup', ColumnGroup);
Vue.component('ui-confirmdialog', ConfirmDialog);
Vue.component('ui-confirmpopup', ConfirmPopup);
Vue.component('ui-contextmenu', ContextMenu);
Vue.component('ui-contextmenusub', ContextMenuSub);
Vue.component('ui-datatable', DataTable);
Vue.component('ui-dataview', DataView);
Vue.component('ui-dataviewlayoutoptions', DataViewLayoutOptions);
Vue.component('ui-deferredcontent', DeferredContent);
Vue.component('ui-dialog', Dialog);
Vue.component('ui-divider', Divider);
Vue.component('ui-dock', Dock);
Vue.component('ui-dropdown', Dropdown);
Vue.component('ui-dynamicdialog', DynamicDialog);
Vue.component('ui-editor', Editor);
Vue.component('ui-fieldset', Fieldset);
Vue.component('ui-fileupload', FileUpload);
Vue.component('ui-fullcalendar', FullCalendar);
Vue.component('ui-galleria', Galleria);
Vue.component('ui-image', Image);
Vue.component('ui-inlinemessage', InlineMessage);
Vue.component('ui-inplace', Inplace);
Vue.component('ui-inputmask', InputMask);
Vue.component('ui-inputnumber', InputNumber);
Vue.component('ui-inputswitch', InputSwitch);
Vue.component('ui-inputtext', InputText);
Vue.component('ui-knob', Knob);
Vue.component('ui-listbox', Listbox);
Vue.component('ui-megamenu', MegaMenu);
Vue.component('ui-menu', Menu);
Vue.component('ui-menubar', Menubar);
Vue.component('ui-message', Message);
Vue.component('ui-multiselect', MultiSelect);
Vue.component('ui-orderlist', OrderList);
Vue.component('ui-organizationchart', OrganizationChart);
Vue.component('ui-overlaypanel', OverlayPanel);
Vue.component('ui-paginator', Paginator);
Vue.component('ui-panel', Panel);
Vue.component('ui-panelmenu', PanelMenu);
Vue.component('ui-password', Password);
Vue.component('ui-picklist', PickList);
Vue.component('ui-portal', Portal);
Vue.component('ui-progressbar', ProgressBar);
Vue.component('ui-progressspinner', ProgressSpinner);
Vue.component('ui-radiobutton', RadioButton);
Vue.component('ui-rating', Rating);
Vue.component('ui-row', Row);
Vue.component('ui-scrollpanel', ScrollPanel);
Vue.component('ui-scrolltop', ScrollTop);
Vue.component('ui-selectbutton', SelectButton);
Vue.component('ui-sidebar', Sidebar);
Vue.component('ui-skeleton', Skeleton);
Vue.component('ui-slider', Slider);
Vue.component('ui-speeddial', SpeedDial);
Vue.component('ui-splitbutton', SplitButton);
Vue.component('ui-splitter', Splitter);
Vue.component('ui-splitterpanel', SplitterPanel);
Vue.component('ui-steps', Steps);
Vue.component('ui-tabmenu', TabMenu);
Vue.component('ui-tabpanel', TabPanel);
Vue.component('ui-tabview', TabView);
Vue.component('ui-tag', Tag);
Vue.component('ui-terminal', Terminal);
Vue.component('ui-textarea', Textarea);
Vue.component('ui-tieredmenu', TieredMenu);
Vue.component('ui-timeline', Timeline);
Vue.component('ui-toast', Toast);
Vue.component('ui-togglebutton', ToggleButton);
Vue.component('ui-toolbar', Toolbar);
Vue.component('ui-tree', Tree);
Vue.component('ui-treeselect', TreeSelect);
Vue.component('ui-treetable', TreeTable);
Vue.component('ui-tristatecheckbox', TriStateCheckbox);
Vue.component('ui-virtualscroller', VirtualScroller);

Vue.directive('tooltip', Tooltip);

Vue.mount('#app')
