/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanApiResult,
    BooleanApiResultFromJSON,
    RunnerApiResult,
    RunnerApiResultFromJSON,
    RunnerInsertDto,
    RunnerInsertDtoToJSON,
    RunnerIReadOnlyCollectionApiResult,
    RunnerIReadOnlyCollectionApiResultFromJSON,
    RunnerPageOfApiResult,
    RunnerPageOfApiResultFromJSON,
    RunnerUpdateDto,
    RunnerUpdateDtoToJSON,
} from '../models';

export interface RunnerDeleteByIdRequest {
    id: string;
}

export interface RunnerGetByIdRequest {
    id: string;
}

export interface RunnerGetForWorkflowByIdRequest {
    id: string;
    page?: number;
    perpage?: number;
}

export interface RunnerGetPageRequest {
    page?: number;
    perPage?: number;
}

export interface RunnerInsertRequest {
    runnerInsertDto?: RunnerInsertDto;
}

export interface RunnerSearchRequest {
    criteria?: string;
    page?: number;
    perPage?: number;
}

export interface RunnerToggleEnableRequest {
    id?: string;
}

export interface RunnerUpdateRequest {
    runnerUpdateDto?: RunnerUpdateDto;
}

export interface RunnerUpdateStatusRequest {
    id?: string;
    status?: string;
}

export interface RunnerUpdateStatus0Request {
    id?: string;
    status?: string;
}

/**
 * RunnerApi - interface
 *
 * @export
 * @interface RunnerApiInterface
 */
export interface RunnerApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerDeleteByIdRaw(requestParameters: RunnerDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    runnerDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerIReadOnlyCollectionApiResult>>;

    /**
     */
    runnerGet(initOverrides?: RequestInit): Promise<RunnerIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerGetByIdRaw(requestParameters: RunnerGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>>;

    /**
     */
    runnerGetById(id: string, initOverrides?: RequestInit): Promise<RunnerApiResult>;

    /**
     *
     * @param {string} id
     * @param {number} [page]
     * @param {number} [perpage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerGetForWorkflowByIdRaw(requestParameters: RunnerGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerPageOfApiResult>>;

    /**
     */
    runnerGetForWorkflowById(id: string, page?: number, perpage?: number, initOverrides?: RequestInit): Promise<RunnerPageOfApiResult>;

    /**
     *
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerGetPageRaw(requestParameters: RunnerGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerPageOfApiResult>>;

    /**
     */
    runnerGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<RunnerPageOfApiResult>;

    /**
     *
     * @param {RunnerInsertDto} [runnerInsertDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerInsertRaw(requestParameters: RunnerInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>>;

    /**
     */
    runnerInsert(runnerInsertDto?: RunnerInsertDto, initOverrides?: RequestInit): Promise<RunnerApiResult>;

    /**
     *
     * @param {string} [criteria]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerSearchRaw(requestParameters: RunnerSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerIReadOnlyCollectionApiResult>>;

    /**
     */
    runnerSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<RunnerIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerToggleEnableRaw(requestParameters: RunnerToggleEnableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>>;

    /**
     */
    runnerToggleEnable(id?: string, initOverrides?: RequestInit): Promise<RunnerApiResult>;

    /**
     *
     * @param {RunnerUpdateDto} [runnerUpdateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerUpdateRaw(requestParameters: RunnerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>>;

    /**
     */
    runnerUpdate(runnerUpdateDto?: RunnerUpdateDto, initOverrides?: RequestInit): Promise<RunnerApiResult>;

    /**
     *
     * @param {string} [id]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerUpdateStatusRaw(requestParameters: RunnerUpdateStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>>;

    /**
     */
    runnerUpdateStatus(id?: string, status?: string, initOverrides?: RequestInit): Promise<RunnerApiResult>;

    /**
     *
     * @param {string} [id]
     * @param {string} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RunnerApiInterface
     */
    runnerUpdateStatus_1Raw(requestParameters: RunnerUpdateStatus0Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>>;

    /**
     */
    runnerUpdateStatus_1(id?: string, status?: string, initOverrides?: RequestInit): Promise<RunnerApiResult>;

}

/**
 *
 */
export class RunnerApi extends runtime.BaseAPI implements RunnerApiInterface {

    /**
     */
    async runnerDeleteByIdRaw(requestParameters: RunnerDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling runnerDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.runnerDeleteByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerGet(initOverrides?: RequestInit): Promise<RunnerIReadOnlyCollectionApiResult> {
        const response = await this.runnerGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerGetByIdRaw(requestParameters: RunnerGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling runnerGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerGetById(id: string, initOverrides?: RequestInit): Promise<RunnerApiResult> {
        const response = await this.runnerGetByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerGetForWorkflowByIdRaw(requestParameters: RunnerGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerPageOfApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling runnerGetForWorkflowById.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perpage !== undefined) {
            queryParameters['perpage'] = requestParameters.perpage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/GetForWorkflow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerGetForWorkflowById(id: string, page?: number, perpage?: number, initOverrides?: RequestInit): Promise<RunnerPageOfApiResult> {
        const response = await this.runnerGetForWorkflowByIdRaw({id: id, page: page, perpage: perpage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerGetPageRaw(requestParameters: RunnerGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerPageOfApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<RunnerPageOfApiResult> {
        const response = await this.runnerGetPageRaw({page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerInsertRaw(requestParameters: RunnerInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RunnerInsertDtoToJSON(requestParameters.runnerInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerInsert(runnerInsertDto?: RunnerInsertDto, initOverrides?: RequestInit): Promise<RunnerApiResult> {
        const response = await this.runnerInsertRaw({runnerInsertDto: runnerInsertDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerSearchRaw(requestParameters: RunnerSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<RunnerIReadOnlyCollectionApiResult> {
        const response = await this.runnerSearchRaw({criteria: criteria, page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerToggleEnableRaw(requestParameters: RunnerToggleEnableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/ToggleEnable`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerToggleEnable(id?: string, initOverrides?: RequestInit): Promise<RunnerApiResult> {
        const response = await this.runnerToggleEnableRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerUpdateRaw(requestParameters: RunnerUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RunnerUpdateDtoToJSON(requestParameters.runnerUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerUpdate(runnerUpdateDto?: RunnerUpdateDto, initOverrides?: RequestInit): Promise<RunnerApiResult> {
        const response = await this.runnerUpdateRaw({runnerUpdateDto: runnerUpdateDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerUpdateStatusRaw(requestParameters: RunnerUpdateStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/UpdateStatus`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerUpdateStatus(id?: string, status?: string, initOverrides?: RequestInit): Promise<RunnerApiResult> {
        const response = await this.runnerUpdateStatusRaw({id: id, status: status}, initOverrides);
        return await response.value();
    }

    /**
     */
    async runnerUpdateStatus_1Raw(requestParameters: RunnerUpdateStatus0Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RunnerApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Runner/UpdateStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RunnerApiResultFromJSON(jsonValue));
    }

    /**
     */
    async runnerUpdateStatus_1(id?: string, status?: string, initOverrides?: RequestInit): Promise<RunnerApiResult> {
        const response = await this.runnerUpdateStatus_1Raw({id: id, status: status}, initOverrides);
        return await response.value();
    }

}
