/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Job, JobFromJSON, JobToJSON,} from './Job';
import {Workflow, WorkflowFromJSON, WorkflowToJSON,} from './Workflow';

/**
 *
 * @export
 * @interface JobWorkflow
 */
export interface JobWorkflow {
    /**
     *
     * @type {Job}
     * @memberof JobWorkflow
     */
    job?: Job;
    /**
     *
     * @type {Workflow}
     * @memberof JobWorkflow
     */
    workflow?: Workflow;
}

export function JobWorkflowFromJSON(json: any): JobWorkflow {
    return JobWorkflowFromJSONTyped(json, false);
}

export function JobWorkflowFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobWorkflow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'job': !exists(json, 'job') ? undefined : JobFromJSON(json['job']),
        'workflow': !exists(json, 'workflow') ? undefined : WorkflowFromJSON(json['workflow']),
    };
}

export function JobWorkflowToJSON(value?: JobWorkflow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'job': JobToJSON(value.job),
        'workflow': WorkflowToJSON(value.workflow),
    };
}

