/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Runner, RunnerFromJSON, RunnerToJSON,} from './Runner';

/**
 *
 * @export
 * @interface RunnerIReadOnlyCollectionApiResult
 */
export interface RunnerIReadOnlyCollectionApiResult {
    /**
     *
     * @type {boolean}
     * @memberof RunnerIReadOnlyCollectionApiResult
     */
    success?: boolean;
    /**
     *
     * @type {Array<Runner>}
     * @memberof RunnerIReadOnlyCollectionApiResult
     */
    content?: Array<Runner> | null;
    /**
     *
     * @type {string}
     * @memberof RunnerIReadOnlyCollectionApiResult
     */
    message?: string | null;
}

export function RunnerIReadOnlyCollectionApiResultFromJSON(json: any): RunnerIReadOnlyCollectionApiResult {
    return RunnerIReadOnlyCollectionApiResultFromJSONTyped(json, false);
}

export function RunnerIReadOnlyCollectionApiResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunnerIReadOnlyCollectionApiResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'success': !exists(json, 'success') ? undefined : json['success'],
        'content': !exists(json, 'content') ? undefined : (json['content'] === null ? null : (json['content'] as Array<any>).map(RunnerFromJSON)),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function RunnerIReadOnlyCollectionApiResultToJSON(value?: RunnerIReadOnlyCollectionApiResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'success': value.success,
        'content': value.content === undefined ? undefined : (value.content === null ? null : (value.content as Array<any>).map(RunnerToJSON)),
        'message': value.message,
    };
}

