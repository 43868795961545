/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanApiResult,
    BooleanApiResultFromJSON,
    User,
    UserApiResult,
    UserApiResultFromJSON,
    UserDto,
    UserDtoToJSON,
    UserListApiResult,
    UserListApiResultFromJSON,
    UserToJSON,
} from '../models';

export interface UserDeleteByIdRequest {
    id: string;
}

export interface UserGetByIdRequest {
    id: string;
}

export interface UserGetPageRequest {
    page?: number;
    perPage?: number;
    sort?: string;
    filter?: string;
}

export interface UserInsertRequest {
    userDto?: UserDto;
}

export interface UserUpdateRequest {
    user?: User;
}

/**
 * UserApi - interface
 *
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userDeleteByIdRaw(requestParameters: UserDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    userDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserListApiResult>>;

    /**
     */
    userGet(initOverrides?: RequestInit): Promise<UserListApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userGetByIdRaw(requestParameters: UserGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserApiResult>>;

    /**
     */
    userGetById(id: string, initOverrides?: RequestInit): Promise<UserApiResult>;

    /**
     *
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {string} [sort]
     * @param {string} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userGetPageRaw(requestParameters: UserGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserListApiResult>>;

    /**
     */
    userGetPage(page?: number, perPage?: number, sort?: string, filter?: string, initOverrides?: RequestInit): Promise<UserListApiResult>;

    /**
     *
     * @param {UserDto} [userDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userInsertRaw(requestParameters: UserInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserApiResult>>;

    /**
     */
    userInsert(userDto?: UserDto, initOverrides?: RequestInit): Promise<UserApiResult>;

    /**
     *
     * @param {User} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    userUpdateRaw(requestParameters: UserUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserApiResult>>;

    /**
     */
    userUpdate(user?: User, initOverrides?: RequestInit): Promise<UserApiResult>;

}

/**
 *
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     */
    async userDeleteByIdRaw(requestParameters: UserDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling userDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/User/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async userDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.userDeleteByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserListApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/User/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListApiResultFromJSON(jsonValue));
    }

    /**
     */
    async userGet(initOverrides?: RequestInit): Promise<UserListApiResult> {
        const response = await this.userGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetByIdRaw(requestParameters: UserGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling userGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/User/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserApiResultFromJSON(jsonValue));
    }

    /**
     */
    async userGetById(id: string, initOverrides?: RequestInit): Promise<UserApiResult> {
        const response = await this.userGetByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetPageRaw(requestParameters: UserGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserListApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/User/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserListApiResultFromJSON(jsonValue));
    }

    /**
     */
    async userGetPage(page?: number, perPage?: number, sort?: string, filter?: string, initOverrides?: RequestInit): Promise<UserListApiResult> {
        const response = await this.userGetPageRaw({
            page: page,
            perPage: perPage,
            sort: sort,
            filter: filter
        }, initOverrides);
        return await response.value();
    }

    /**
     */
    async userInsertRaw(requestParameters: UserInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/User/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserApiResultFromJSON(jsonValue));
    }

    /**
     */
    async userInsert(userDto?: UserDto, initOverrides?: RequestInit): Promise<UserApiResult> {
        const response = await this.userInsertRaw({userDto: userDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async userUpdateRaw(requestParameters: UserUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/User/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserApiResultFromJSON(jsonValue));
    }

    /**
     */
    async userUpdate(user?: User, initOverrides?: RequestInit): Promise<UserApiResult> {
        const response = await this.userUpdateRaw({user: user}, initOverrides);
        return await response.value();
    }

}
