/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanApiResult,
    BooleanApiResultFromJSON,
    JobApiResult,
    JobApiResultFromJSON,
    Workflow,
    WorkflowApiResult,
    WorkflowApiResultFromJSON,
    WorkflowInsertDto,
    WorkflowInsertDtoToJSON,
    WorkflowIReadOnlyCollectionApiResult,
    WorkflowIReadOnlyCollectionApiResultFromJSON,
    WorkflowPageOfApiResult,
    WorkflowPageOfApiResultFromJSON,
    WorkflowToJSON,
    WorkflowUpdateDto,
    WorkflowUpdateDtoToJSON,
} from '../models';

export interface WorkflowDeleteByIdRequest {
    id: string;
}

export interface WorkflowExportRequest {
    id?: string;
}

export interface WorkflowGetByIdRequest {
    id: string;
}

export interface WorkflowGetForProjectPageRequest {
    id?: string;
    page?: number;
    perPage?: number;
}

export interface WorkflowGetPageRequest {
    page?: number;
    perPage?: number;
}

export interface WorkflowImportRequest {
    projectId?: string;
    workflow?: Workflow;
}

export interface WorkflowInsertRequest {
    workflowInsertDto?: WorkflowInsertDto;
}

export interface WorkflowRunByIdRequest {
    id: string;
}

export interface WorkflowRunById0Request {
    id: string;
    body: string;
}

export interface WorkflowSearchRequest {
    criteria?: string;
    page?: number;
    perPage?: number;
}

export interface WorkflowUpdateRequest {
    workflowUpdateDto?: WorkflowUpdateDto;
}

/**
 * WorkflowApi - interface
 *
 * @export
 * @interface WorkflowApiInterface
 */
export interface WorkflowApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowDeleteByIdRaw(requestParameters: WorkflowDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    workflowDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {string} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowExportRaw(requestParameters: WorkflowExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowApiResult>>;

    /**
     */
    workflowExport(id?: string, initOverrides?: RequestInit): Promise<WorkflowApiResult>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowGet(initOverrides?: RequestInit): Promise<WorkflowIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowGetByIdRaw(requestParameters: WorkflowGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowApiResult>>;

    /**
     */
    workflowGetById(id: string, initOverrides?: RequestInit): Promise<WorkflowApiResult>;

    /**
     *
     * @param {string} [id]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowGetForProjectPageRaw(requestParameters: WorkflowGetForProjectPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowPageOfApiResult>>;

    /**
     */
    workflowGetForProjectPage(id?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowPageOfApiResult>;

    /**
     *
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowGetPageRaw(requestParameters: WorkflowGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowPageOfApiResult>>;

    /**
     */
    workflowGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowPageOfApiResult>;

    /**
     *
     * @param {string} [projectId]
     * @param {Workflow} [workflow]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowImportRaw(requestParameters: WorkflowImportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    workflowImport(projectId?: string, workflow?: Workflow, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {WorkflowInsertDto} [workflowInsertDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowInsertRaw(requestParameters: WorkflowInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowApiResult>>;

    /**
     */
    workflowInsert(workflowInsertDto?: WorkflowInsertDto, initOverrides?: RequestInit): Promise<WorkflowApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowRunByIdRaw(requestParameters: WorkflowRunByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>>;

    /**
     */
    workflowRunById(id: string, initOverrides?: RequestInit): Promise<JobApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowRunById_1Raw(requestParameters: WorkflowRunById0Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>>;

    /**
     */
    workflowRunById_1(id: string, body: string, initOverrides?: RequestInit): Promise<JobApiResult>;

    /**
     *
     * @param {string} [criteria]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowSearchRaw(requestParameters: WorkflowSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {WorkflowUpdateDto} [workflowUpdateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowApiInterface
     */
    workflowUpdateRaw(requestParameters: WorkflowUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowApiResult>>;

    /**
     */
    workflowUpdate(workflowUpdateDto?: WorkflowUpdateDto, initOverrides?: RequestInit): Promise<WorkflowApiResult>;

}

/**
 *
 */
export class WorkflowApi extends runtime.BaseAPI implements WorkflowApiInterface {

    /**
     */
    async workflowDeleteByIdRaw(requestParameters: WorkflowDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.workflowDeleteByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowExportRaw(requestParameters: WorkflowExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowExport(id?: string, initOverrides?: RequestInit): Promise<WorkflowApiResult> {
        const response = await this.workflowExportRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowGet(initOverrides?: RequestInit): Promise<WorkflowIReadOnlyCollectionApiResult> {
        const response = await this.workflowGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowGetByIdRaw(requestParameters: WorkflowGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowGetById(id: string, initOverrides?: RequestInit): Promise<WorkflowApiResult> {
        const response = await this.workflowGetByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowGetForProjectPageRaw(requestParameters: WorkflowGetForProjectPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowPageOfApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/GetForProjectPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowGetForProjectPage(id?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowPageOfApiResult> {
        const response = await this.workflowGetForProjectPageRaw({id: id, page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowGetPageRaw(requestParameters: WorkflowGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowPageOfApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowPageOfApiResult> {
        const response = await this.workflowGetPageRaw({page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowImportRaw(requestParameters: WorkflowImportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.projectId !== undefined) {
            queryParameters['projectId'] = requestParameters.projectId;
        }

        const headerParameters: runtime.HTTPHeaders = {'Content-Type': 'application/json'};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Import`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowToJSON(requestParameters.workflow),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowImport(projectId?: string, workflow?: Workflow, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.workflowImportRaw({projectId: projectId, workflow: workflow}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowInsertRaw(requestParameters: WorkflowInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowInsertDtoToJSON(requestParameters.workflowInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowInsert(workflowInsertDto?: WorkflowInsertDto, initOverrides?: RequestInit): Promise<WorkflowApiResult> {
        const response = await this.workflowInsertRaw({workflowInsertDto: workflowInsertDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowRunByIdRaw(requestParameters: WorkflowRunByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowRunById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Run/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowRunById(id: string, initOverrides?: RequestInit): Promise<JobApiResult> {
        const response = await this.workflowRunByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowRunById_1Raw(requestParameters: WorkflowRunById0Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<JobApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowRunById_1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {"Content-Type": "application/json"};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Run/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JSON.parse(requestParameters.body)
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowRunById_1(id: string, body: string, initOverrides?: RequestInit): Promise<JobApiResult> {
        const response = await this.workflowRunById_1Raw({id: id, body: body}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowSearchRaw(requestParameters: WorkflowSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowIReadOnlyCollectionApiResult> {
        const response = await this.workflowSearchRaw({
            criteria: criteria,
            page: page,
            perPage: perPage
        }, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowUpdateRaw(requestParameters: WorkflowUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Workflow/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowUpdateDtoToJSON(requestParameters.workflowUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowUpdate(workflowUpdateDto?: WorkflowUpdateDto, initOverrides?: RequestInit): Promise<WorkflowApiResult> {
        const response = await this.workflowUpdateRaw({workflowUpdateDto: workflowUpdateDto}, initOverrides);
        return await response.value();
    }

}
