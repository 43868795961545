/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface Authorization
 */
export interface Authorization {
    /**
     *
     * @type {string}
     * @memberof Authorization
     */
    accessToken?: string | null;
    /**
     *
     * @type {Date}
     * @memberof Authorization
     */
    expires?: Date;
    /**
     *
     * @type {string}
     * @memberof Authorization
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof Authorization
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Authorization
     */
    role?: string | null;
}

export function AuthorizationFromJSON(json: any): Authorization {
    return AuthorizationFromJSONTyped(json, false);
}

export function AuthorizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Authorization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'accessToken': !exists(json, 'access_token') ? undefined : json['access_token'],
        'expires': !exists(json, 'expires') ? undefined : (new Date(json['expires'])),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function AuthorizationToJSON(value?: Authorization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'access_token': value.accessToken,
        'expires': value.expires === undefined ? undefined : (value.expires.toISOString()),
        'userId': value.userId,
        'name': value.name,
        'role': value.role,
    };
}

