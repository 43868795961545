/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface WorkflowScriptInsertDto
 */
export interface WorkflowScriptInsertDto {
    /**
     *
     * @type {string}
     * @memberof WorkflowScriptInsertDto
     */
    workflowId?: string;
    /**
     *
     * @type {string}
     * @memberof WorkflowScriptInsertDto
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof WorkflowScriptInsertDto
     */
    script?: string | null;
}

export function WorkflowScriptInsertDtoFromJSON(json: any): WorkflowScriptInsertDto {
    return WorkflowScriptInsertDtoFromJSONTyped(json, false);
}

export function WorkflowScriptInsertDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowScriptInsertDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'script': !exists(json, 'script') ? undefined : json['script'],
    };
}

export function WorkflowScriptInsertDtoToJSON(value?: WorkflowScriptInsertDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'workflowId': value.workflowId,
        'name': value.name,
        'script': value.script,
    };
}

