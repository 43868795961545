/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface ComponentSettingDeifnition
 */
export interface ComponentSettingDeifnition {
    /**
     *
     * @type {string}
     * @memberof ComponentSettingDeifnition
     */
    type?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ComponentSettingDeifnition
     */
    required?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof ComponentSettingDeifnition
     */
    options?: Array<string> | null;
}

export function ComponentSettingDeifnitionFromJSON(json: any): ComponentSettingDeifnition {
    return ComponentSettingDeifnitionFromJSONTyped(json, false);
}

export function ComponentSettingDeifnitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentSettingDeifnition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'type': !exists(json, 'type') ? undefined : json['type'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'options': !exists(json, 'options') ? undefined : json['options'],
    };
}

export function ComponentSettingDeifnitionToJSON(value?: ComponentSettingDeifnition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'type': value.type,
        'required': value.required,
        'options': value.options,
    };
}

