/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Runner, RunnerFromJSON, RunnerToJSON,} from './Runner';
import {Workflow, WorkflowFromJSON, WorkflowToJSON,} from './Workflow';

/**
 *
 * @export
 * @interface Job
 */
export interface Job {
    /**
     *
     * @type {string}
     * @memberof Job
     */
    jobId?: string;
    /**
     *
     * @type {string}
     * @memberof Job
     */
    workflowId?: string;
    /**
     *
     * @type {string}
     * @memberof Job
     */
    runnerId?: string | null;
    /**
     *
     * @type {Date}
     * @memberof Job
     */
    startDate?: Date | null;
    /**
     *
     * @type {Date}
     * @memberof Job
     */
    endDate?: Date | null;
    /**
     *
     * @type {string}
     * @memberof Job
     */
    status?: string | null;
    /**
     *
     * @type {string}
     * @memberof Job
     */
    log?: string | null;
    /**
     *
     * @type {Date}
     * @memberof Job
     */
    dateCreated?: Date;
    /**
     *
     * @type {string}
     * @memberof Job
     */
    data?: string | null;
    /**
     *
     * @type {Workflow}
     * @memberof Job
     */
    workflow?: Workflow;
    /**
     *
     * @type {Runner}
     * @memberof Job
     */
    runner?: Runner;
}

export function JobFromJSON(json: any): Job {
    return JobFromJSONTyped(json, false);
}

export function JobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Job {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'jobId': !exists(json, 'jobId') ? undefined : json['jobId'],
        'workflowId': !exists(json, 'workflowId') ? undefined : json['workflowId'],
        'runnerId': !exists(json, 'runnerId') ? undefined : json['runnerId'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'log': !exists(json, 'log') ? undefined : json['log'],
        'dateCreated': !exists(json, 'dateCreated') ? undefined : (new Date(json['dateCreated'])),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'workflow': !exists(json, 'workflow') ? undefined : WorkflowFromJSON(json['workflow']),
        'runner': !exists(json, 'runner') ? undefined : RunnerFromJSON(json['runner']),
    };
}

export function JobToJSON(value?: Job | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'jobId': value.jobId,
        'workflowId': value.workflowId,
        'runnerId': value.runnerId,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'status': value.status,
        'log': value.log,
        'dateCreated': value.dateCreated === undefined ? undefined : (value.dateCreated.toISOString()),
        'data': value.data,
        'workflow': WorkflowToJSON(value.workflow),
        'runner': RunnerToJSON(value.runner),
    };
}

