/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';

/**
 *
 * @export
 * @interface RunnerInsertDto
 */
export interface RunnerInsertDto {
    /**
     *
     * @type {string}
     * @memberof RunnerInsertDto
     */
    status?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof RunnerInsertDto
     */
    enabled?: boolean;
    /**
     *
     * @type {string}
     * @memberof RunnerInsertDto
     */
    settings?: string | null;
}

export function RunnerInsertDtoFromJSON(json: any): RunnerInsertDto {
    return RunnerInsertDtoFromJSONTyped(json, false);
}

export function RunnerInsertDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunnerInsertDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'status': !exists(json, 'status') ? undefined : json['status'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'settings': !exists(json, 'settings') ? undefined : json['settings'],
    };
}

export function RunnerInsertDtoToJSON(value?: RunnerInsertDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'status': value.status,
        'enabled': value.enabled,
        'settings': value.settings,
    };
}

