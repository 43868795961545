/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanApiResult,
    BooleanApiResultFromJSON,
    WorkflowScriptApiResult,
    WorkflowScriptApiResultFromJSON,
    WorkflowScriptInsertDto,
    WorkflowScriptInsertDtoToJSON,
    WorkflowScriptIReadOnlyCollectionApiResult,
    WorkflowScriptIReadOnlyCollectionApiResultFromJSON,
    WorkflowScriptPageOfApiResult,
    WorkflowScriptPageOfApiResultFromJSON,
    WorkflowScriptUpdateDto,
    WorkflowScriptUpdateDtoToJSON,
} from '../models';

export interface WorkflowScriptDeleteByIdRequest {
    id: string;
}

export interface WorkflowScriptGetByIdRequest {
    id: string;
}

export interface WorkflowScriptGetByNameByIdRequest {
    id: string;
    name?: string;
}

export interface WorkflowScriptGetForWorkflowByIdRequest {
    id: string;
    page?: number;
    perpage?: number;
}

export interface WorkflowScriptGetPageRequest {
    page?: number;
    perPage?: number;
}

export interface WorkflowScriptInsertRequest {
    workflowScriptInsertDto?: WorkflowScriptInsertDto;
}

export interface WorkflowScriptSearchRequest {
    criteria?: string;
    page?: number;
    perPage?: number;
}

export interface WorkflowScriptUpdateRequest {
    workflowScriptUpdateDto?: WorkflowScriptUpdateDto;
}

/**
 * WorkflowScriptApi - interface
 *
 * @export
 * @interface WorkflowScriptApiInterface
 */
export interface WorkflowScriptApiInterface {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptDeleteByIdRaw(requestParameters: WorkflowScriptDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>>;

    /**
     */
    workflowScriptDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult>;

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowScriptGet(initOverrides?: RequestInit): Promise<WorkflowScriptIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptGetByIdRaw(requestParameters: WorkflowScriptGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptApiResult>>;

    /**
     */
    workflowScriptGetById(id: string, initOverrides?: RequestInit): Promise<WorkflowScriptApiResult>;

    /**
     *
     * @param {string} id
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptGetByNameByIdRaw(requestParameters: WorkflowScriptGetByNameByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptApiResult>>;

    /**
     */
    workflowScriptGetByNameById(id: string, name?: string, initOverrides?: RequestInit): Promise<WorkflowScriptApiResult>;

    /**
     *
     * @param {string} id
     * @param {number} [page]
     * @param {number} [perpage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptGetForWorkflowByIdRaw(requestParameters: WorkflowScriptGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptPageOfApiResult>>;

    /**
     */
    workflowScriptGetForWorkflowById(id: string, page?: number, perpage?: number, initOverrides?: RequestInit): Promise<WorkflowScriptPageOfApiResult>;

    /**
     *
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptGetPageRaw(requestParameters: WorkflowScriptGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptPageOfApiResult>>;

    /**
     */
    workflowScriptGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowScriptPageOfApiResult>;

    /**
     *
     * @param {WorkflowScriptInsertDto} [workflowScriptInsertDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptInsertRaw(requestParameters: WorkflowScriptInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptApiResult>>;

    /**
     */
    workflowScriptInsert(workflowScriptInsertDto?: WorkflowScriptInsertDto, initOverrides?: RequestInit): Promise<WorkflowScriptApiResult>;

    /**
     *
     * @param {string} [criteria]
     * @param {number} [page]
     * @param {number} [perPage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptSearchRaw(requestParameters: WorkflowScriptSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptIReadOnlyCollectionApiResult>>;

    /**
     */
    workflowScriptSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowScriptIReadOnlyCollectionApiResult>;

    /**
     *
     * @param {WorkflowScriptUpdateDto} [workflowScriptUpdateDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkflowScriptApiInterface
     */
    workflowScriptUpdateRaw(requestParameters: WorkflowScriptUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptApiResult>>;

    /**
     */
    workflowScriptUpdate(workflowScriptUpdateDto?: WorkflowScriptUpdateDto, initOverrides?: RequestInit): Promise<WorkflowScriptApiResult>;

}

/**
 *
 */
export class WorkflowScriptApi extends runtime.BaseAPI implements WorkflowScriptApiInterface {

    /**
     */
    async workflowScriptDeleteByIdRaw(requestParameters: WorkflowScriptDeleteByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowScriptDeleteById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptDeleteById(id: string, initOverrides?: RequestInit): Promise<BooleanApiResult> {
        const response = await this.workflowScriptDeleteByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowScriptGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/Get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowScriptIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptGet(initOverrides?: RequestInit): Promise<WorkflowScriptIReadOnlyCollectionApiResult> {
        const response = await this.workflowScriptGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowScriptGetByIdRaw(requestParameters: WorkflowScriptGetByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowScriptGetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/Get/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowScriptApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptGetById(id: string, initOverrides?: RequestInit): Promise<WorkflowScriptApiResult> {
        const response = await this.workflowScriptGetByIdRaw({id: id}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowScriptGetByNameByIdRaw(requestParameters: WorkflowScriptGetByNameByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowScriptGetByNameById.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/GetByName/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowScriptApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptGetByNameById(id: string, name?: string, initOverrides?: RequestInit): Promise<WorkflowScriptApiResult> {
        const response = await this.workflowScriptGetByNameByIdRaw({id: id, name: name}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowScriptGetForWorkflowByIdRaw(requestParameters: WorkflowScriptGetForWorkflowByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptPageOfApiResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling workflowScriptGetForWorkflowById.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perpage !== undefined) {
            queryParameters['perpage'] = requestParameters.perpage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/GetForWorkflow/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowScriptPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptGetForWorkflowById(id: string, page?: number, perpage?: number, initOverrides?: RequestInit): Promise<WorkflowScriptPageOfApiResult> {
        const response = await this.workflowScriptGetForWorkflowByIdRaw({
            id: id,
            page: page,
            perpage: perpage
        }, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowScriptGetPageRaw(requestParameters: WorkflowScriptGetPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptPageOfApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowScriptPageOfApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptGetPage(page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowScriptPageOfApiResult> {
        const response = await this.workflowScriptGetPageRaw({page: page, perPage: perPage}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowScriptInsertRaw(requestParameters: WorkflowScriptInsertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/Insert`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowScriptInsertDtoToJSON(requestParameters.workflowScriptInsertDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowScriptApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptInsert(workflowScriptInsertDto?: WorkflowScriptInsertDto, initOverrides?: RequestInit): Promise<WorkflowScriptApiResult> {
        const response = await this.workflowScriptInsertRaw({workflowScriptInsertDto: workflowScriptInsertDto}, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowScriptSearchRaw(requestParameters: WorkflowScriptSearchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptIReadOnlyCollectionApiResult>> {
        const queryParameters: any = {};

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/Search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowScriptIReadOnlyCollectionApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptSearch(criteria?: string, page?: number, perPage?: number, initOverrides?: RequestInit): Promise<WorkflowScriptIReadOnlyCollectionApiResult> {
        const response = await this.workflowScriptSearchRaw({
            criteria: criteria,
            page: page,
            perPage: perPage
        }, initOverrides);
        return await response.value();
    }

    /**
     */
    async workflowScriptUpdateRaw(requestParameters: WorkflowScriptUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WorkflowScriptApiResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/WorkflowScript/Update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkflowScriptUpdateDtoToJSON(requestParameters.workflowScriptUpdateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowScriptApiResultFromJSON(jsonValue));
    }

    /**
     */
    async workflowScriptUpdate(workflowScriptUpdateDto?: WorkflowScriptUpdateDto, initOverrides?: RequestInit): Promise<WorkflowScriptApiResult> {
        const response = await this.workflowScriptUpdateRaw({workflowScriptUpdateDto: workflowScriptUpdateDto}, initOverrides);
        return await response.value();
    }

}
