/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '../runtime';
import {Job, JobFromJSON, JobToJSON,} from './Job';

/**
 *
 * @export
 * @interface JobPageOf
 */
export interface JobPageOf {
    /**
     *
     * @type {number}
     * @memberof JobPageOf
     */
    totalRecords?: number;
    /**
     *
     * @type {Array<Job>}
     * @memberof JobPageOf
     */
    data?: Array<Job> | null;
}

export function JobPageOfFromJSON(json: any): JobPageOf {
    return JobPageOfFromJSONTyped(json, false);
}

export function JobPageOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobPageOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(JobFromJSON)),
    };
}

export function JobPageOfToJSON(value?: JobPageOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'totalRecords': value.totalRecords,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(JobToJSON)),
    };
}

