/* tslint:disable */
/* eslint-disable */
/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists, mapValues} from '../runtime';
import {
    ComponentSettingDeifnition,
    ComponentSettingDeifnitionFromJSON,
    ComponentSettingDeifnitionToJSON,
} from './ComponentSettingDeifnition';

/**
 *
 * @export
 * @interface ComponentDefinition
 */
export interface ComponentDefinition {
    /**
     *
     * @type {string}
     * @memberof ComponentDefinition
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComponentDefinition
     */
    icon?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComponentDefinition
     */
    type?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComponentDefinition
     */
    group?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComponentDefinition
     */
    displayName?: string | null;
    /**
     *
     * @type {string}
     * @memberof ComponentDefinition
     */
    description?: string | null;
    /**
     *
     * @type {{ [key: string]: ComponentSettingDeifnition; }}
     * @memberof ComponentDefinition
     */
    settings?: { [key: string]: ComponentSettingDeifnition; } | null;
}

export function ComponentDefinitionFromJSON(json: any): ComponentDefinition {
    return ComponentDefinitionFromJSONTyped(json, false);
}

export function ComponentDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'name': !exists(json, 'name') ? undefined : json['name'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'settings': !exists(json, 'settings') ? undefined : (json['settings'] === null ? null : mapValues(json['settings'], ComponentSettingDeifnitionFromJSON)),
    };
}

export function ComponentDefinitionToJSON(value?: ComponentDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'name': value.name,
        'icon': value.icon,
        'type': value.type,
        'group': value.group,
        'displayName': value.displayName,
        'description': value.description,
        'settings': value.settings === undefined ? undefined : (value.settings === null ? null : mapValues(value.settings, ComponentSettingDeifnitionToJSON)),
    };
}

