/* tslint:disable */
/* eslint-disable */

/**
 * Autom8.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ScheduleStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

export function ScheduleStatusFromJSON(json: any): ScheduleStatus {
    return ScheduleStatusFromJSONTyped(json, false);
}

export function ScheduleStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleStatus {
    return json as ScheduleStatus;
}

export function ScheduleStatusToJSON(value?: ScheduleStatus | null): any {
    return value as any;
}

