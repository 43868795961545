import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/UserView.vue')
    },
    {
        path: '/user/:id',
        name: 'user',
        component: () => import('../views/UserView.vue')
    },
    {
        path: '/workflows/:id',
        name: 'workflows',
        component: () => import('../views/WorkflowsView.vue')
    },
    {
        path: '/workflow/:id',
        name: 'workflow',
        component: () => import('../views/WorkflowView.vue')
    },
    {
        path: '/edit-workflow/:id',
        name: 'editworkflow',
        component: () => import('../views/EditWorkflowView.vue')
    },
    {
        path: '/jobs/',
        name: 'jobs',
        component: () => import('../views/JobsView.vue')
    },
    {
        path: '/runners',
        name: 'runners',
        component: () => import('../views/RunnersView.vue')
    },
    {
        path: '/view-schedules',
        name: 'schedules',
        component: () => import('../views/ScheduleView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
